import React, { useEffect, useState, useRef, useCallback } from "react";
import ToastContainer, { toast, TOAST_POSITION } from "../../components/material/ToastPopup";
import styles from "./EstateManage.module.scss";
import { AdaptiveBodyLayout } from "../../components/layouts/Body";
import { VIEW_MODES } from "../../components/layouts/layoutConst";
import icons from "../../assets/images/icons";
import { RootFrameLayout, ScrollableFrameLayout } from "../../components/layouts/Root";
import { DataTableViewType, EstateManageDataTable } from "./EstateManageBody";
import EstateManageHeader from "./EstateManageHeader";
import { requestEstateList, requestEstateMarker, requestUpdateStateType, requestUpdateAddressOpen, requestUpdateMemo, requestEstateCount, requestDeleteSale } from "../../services/estateManage/estateManageService";
import legacy from '../../utils/legacy';
import { HorizontalFlex } from "../../components/flex/HorizontalFlex";
import { ModelPopup } from "../../components/material/Popup";
import { VerticalFlex } from "../../components/flex/VerticalFlex";
import { RowFlex } from "../../components/flex/RowFlex";



const EstateFilterModel = {
    estateType: {
        label: '부동산 유형',
        options: [
            { value: 16, label: "토지" },

            { value: 12, label: "상업용건물" },
            { value: 10, label: "상가점포" },
            { value: 11, label: "사무실" },
            { value: 2, label: "오피스텔" },
            { value: 17, label: "공장" },
            { value: 19, label: "창고" },
            { value: 18, label: "지식산업센터" },
            { value: 15, label: "숙박시설" },


            { value: 0, label: "아파트" },
            { value: 1, label: "주상복합" },
            { value: 7, label: "연립" },
            { value: 8, label: "다세대" },
            { value: 3, label: "단독" },
            { value: 4, label: "다가구" },
            { value: 6, label: "상가주택" },
            { value: 5, label: "전원주택" },

            { value: 14, label: "기타건물" },

        ],
    },
    priceRange: {
        label: '가격 범위',
        options: [
            { value: 2, label: '1억 이하' },
            { value: 3, label: '1억 ~ 5억' },
            { value: 4, label: '5억 ~ 10억' },
            { value: 5, label: '10억 이상' }
        ],
    },
    areaSize: {
        label: '면적',
        options: [
            { value: 2, label: '30㎡ 이하' },
            { value: 3, label: '30㎡ ~ 50㎡' },
            { value: 4, label: '50㎡ ~ 100㎡' },
            { value: 5, label: '100㎡ 이상' }
        ],
    },
    dealType: {
        label: '거래 유형',
        options: [
            { value: 0, label: '매매' },
            { value: 1, label: '전세' },
            { value: 2, label: '월세' }
        ],
    },
    location: {
        label: '지역',
        options: [
            { value: '서울', label: '서울' },
            { value: '경기', label: '경기' },
            { value: '부산', label: '부산' },
            { value: '대구', label: '대구' },
            { value: '광주', label: '광주' }
        ],
    },
    addressOpen: {
        label: '위치공개',
        options: [
            { value: 1, label: '위치공개' },
            { value: 0, label: '위치비공개' },
        ],
    },
    state: {
        label: '노출상태',
        options: [
            { label: "전체", icon: { selected: icons.saleState.total.on, default: icons.saleState.total.off }, value: '' },
            { label: "노출중", icon: { selected: icons.saleState.visible.on, default: icons.saleState.visible.off }, value: 0 },
            { label: "비노출중", icon: { selected: icons.saleState.invisible.on, default: icons.saleState.invisible.off }, value: 3 },
            { label: "거래완료", icon: { selected: icons.saleState.done.on, default: icons.saleState.done.off }, value: 1 },
        ]
    },
    order: {
        label: "정렬",
        options: [
            { label: "최신순", value: { type: "creation_date", order: "increase" } },
            { label: "오래된 순", value: { type: "creation_date", order: "decrease" } },
            { label: "가격높은순", value: { type: "price", order: "increase" } },
            { label: "가격낮은순", value: { type: "price", order: "decrease" } },
        ]
    },
    limit: {
        label: '',
        options: [
            { label: "20개씩 보기", value: 20 },
            { label: "30개씩 보기", value: 30 },
            { label: "50개씩 보기", value: 50 },
        ]
    }

};

const getOverlay = () => {
    var dspmp = new legacy.DiscoSubPageModeParams();
    dspmp.coverBackground = true;
    dspmp.forwardEvent = true;
    dspmp.helloCallback = function () {
    }
    dspmp.byeCallback = function () {
        if (legacy.estateMarkerAjax != null) {
            legacy.estateMarkerAjax.abort();
            legacy.estateMarkerAjax = null;
        }
        if (legacy.timer_redraw != null) {
            clearTimeout(legacy.timer_redraw);
            legacy.timer_redraw = null;
        }
        if (legacy.timer_area_redraw != null) {
            clearTimeout(legacy.timer_area_redraw);
            legacy.timer_area_redraw = null;
        }
        if (legacy.timer_redraw_overlay_cluster != null) {
            clearTimeout(legacy.timer_redraw_overlay_cluster);
            legacy.timer_redraw_overlay_cluster = null;
        }
        legacy.clear_map();
        legacy.reload(false);
    }
    return new legacy.DiscoSubPageMode(dspmp);
}

const OverlayMode = (getPayload, getLoadMarker) => {
    var dspmp = new legacy.DiscoSubPageModeParams();
    function discoSubPagePinClick(uuid) {
        if (legacy.g_price_markers.hasOwnProperty(uuid)) {
            var d = legacy.g_price_markers[uuid];
            if (d != undefined && d['suid'] != undefined) {
                legacy.em_click_slot('sale', d['suid']);
            }
        }
    }

    dspmp.kakaoEvent.mapClick = function (mouseEvent) {
        var clickLat = mouseEvent.latLng.getLat();
        var clickLng = mouseEvent.latLng.getLng();
        var addr = '';
        var lRev = legacy.get_rev_xy(addr, 'l');
        legacy.get_pnu_by_latlng(
            clickLat - lRev[1],
            clickLng - lRev[0],
            function (result1) {
                for (var uuid in legacy.g_price_markers) {
                    if (legacy.g_price_markers[uuid].pnu == result1['pnu']) {

                        if (legacy.g_price_markers[uuid]['marker'] != undefined && legacy.g_price_markers[uuid]['marker'] != null) {
                            legacy.g_price_markers[uuid]['marker'].setMap(null);
                        }
                        discoSubPagePinClick(uuid);
                        return;
                    }
                }
            }
        );
    }

    dspmp.kakaoEvent.zoom_changed = () => {
        legacy.reload();
    }

    dspmp.kakaoEvent.dragend = function () {
        legacy.reload();
        legacy.drag_end = new Date().getTime();
    }

    dspmp.discoMarkerClickEvent.stagePin = function (selector) {
        var uuid = legacy.jQuery(selector).data('uuid');
        if (legacy.jQuery(selector).find(".map-marker-detail-div").length == 0) {
            discoSubPagePinClick(uuid);
        }
    }

    dspmp.discoMarkerClickEvent.mapAgentMarker = () => { }

    dspmp.discoMapEvent.addPriceMarker = function (data) {
        var r = data[0];
        var isAll = data[2];
        var gray = data[3];
        if (gray == undefined) gray = false;
        var content = '';
        const filters = [{ name: 'sale', d: r['lus'] }, { name: 'auction', d: r['lua'] }];
        filters.sort(legacy.filter_compare);
        content = '<div class="pin_container stage-pin" data-action="pin" data-uuid="' + r['u'] + '">';
        if (r['ao'] == 0) {
            content += legacy.map_none_ads_marker(r, isAll);
        } else {
            if (filters[0].name == 'sale') content += legacy.map_sale_default_marker_sale(r, gray);
            else if (filters[0].name == 'auction') content += legacy.map_auction_default_marker(r, gray);
        }
        content += '</div>';
        var overlay = new legacy.daum.maps.CustomOverlay({
            position: new legacy.daum.maps.LatLng(r['lat'], r['lng']),
            clickable: false,
            content: content,
            zIndex: 3,
            xAnchor: 0.5,
            yAnchor: 1
        });

        overlay.setMap(legacy.map);
        return overlay;
    }

    dspmp.discoMapEvent.currentAgentMarker = () => { }

    dspmp.discoMapEvent.reload = async function () {
        if (!getLoadMarker()) return;
        const payload = getPayload();
        const data = {
            ...payload,
            address_open: payload?.address_open?.join(",") || null,
            rand_type: payload?.rand_type?.join(",") || null,
            deal_type: payload?.deal_type?.join(",") || null,
            dong_type: payload?.location?.join(",") || null,
            sort_type: payload?.order?.[0]?.type,
            sort_order: payload?.order?.[0]?.order,
            limit: payload?.limit?.[0]
        }

        var bounds = legacy.discoMap.getBounds();
        var a = bounds.getSouthWest().getLat();
        var b = bounds.getSouthWest().getLng();
        var c = bounds.getNorthEast().getLat();
        var d = bounds.getNorthEast().getLng();
        data['a'] = a;
        data['b'] = b;
        data['c'] = c;
        data['d'] = d;
        try {
            const result = await requestEstateMarker(data);
            if (legacy.timer_redraw != null) {
                clearTimeout(legacy.timer_redraw);
                legacy.timer_redraw = null;
            }
            if (legacy.timer_area_redraw != null) {
                clearTimeout(legacy.timer_area_redraw);
                legacy.timer_area_redraw = null;
            }
            legacy.redraw(result, 0);
        } catch (err) {

        }
    }

    return dspmp;
}

const DEFAULT_FILTER = {
    category: "sale",
    id: 'is_me',
    order: [EstateFilterModel.order.options[0].value],
    limit: [EstateFilterModel.limit.options[0].value],
    address_open: [],
    deal_type: [],
    rand_type: [],
    state_type: "",
    offset: 0,
}

const RESET_FILTER = {
    address_open: [],
    deal_type: [],
    rand_type: [],
    keyword: null
}

const EstateManagePage = ({ isMobile = false, state = "" }) => {
    const [filterModel, setFilterModel] = useState({ ...EstateFilterModel });
    const [payload, setPayload] = useState(DEFAULT_FILTER);

    const payloadRef = useRef(payload);
    const [data, setData] = useState(null);
    const [checkedList, setCheckedList] = useState([]);

    const [isSideView, setIsSideView] = useState(false);
    const [isMapView, setMapView] = useState(false);
    const [saleCount, setSaleCount] = useState({
        total: 0,
        limit: null,
        expose: 0,
        hide: 0,
        done: 0,
    })

    const [hasMore, setHasMore] = useState(true);
    const markerActiveRef = useRef(isSideView);
    const [isWebScreen, setIsWebScreen] = useState(window.innerWidth > 1000);
    const [openCheckedController, setOpenCheckedController] = useState(false);

    const [openMembershipGuide, setOpenMembershipGuide] = useState(legacy.usr.is && legacy.loggedInUser.isInit && !legacy.loggedInUser.getInfo().membership);

    const overlay = useRef(null);

    const isFirstLoad = useRef(true);

    const handleChecked = useCallback((val) => {
        setCheckedList((prevList) => {
            if (prevList.includes(val)) {
                return prevList.filter((item) => item !== val);
            }
            return [...prevList, val];
        });
    }, []);

    const handleClosePage = () => {
        legacy.bh.go_before();
    }

    const handleResetPayload = () => {
        setPayload((prev) => ({ ...prev, ...RESET_FILTER, offset: 0 }))
    }

    const handleMap = (isActive) => {
        if (isActive) {
            if (!overlay.current) overlay.current = getOverlay();
            legacy.tui_base_close();
            if (legacy.dsv) legacy.dsv.blockUpdateView(true);
            if (legacy.jQuery('#map_filter_box').is(':visible')) legacy.jQuery('#map_filter_box').css('display', 'none');
            if (legacy.jQuery('.map-name-card').is(':visible')) legacy.jQuery(".map-name-card").css('display', 'none')
            overlay.current?.autoAddListener(OverlayMode(() => payloadRef.current, () => markerActiveRef.current));
            if (legacy.timer_redraw != null) {
                clearTimeout(legacy.timer_redraw);
                legacy.timer_redraw = null;
            }
            if (legacy.timer_area_redraw != null) {
                clearTimeout(legacy.timer_area_redraw);
                legacy.timer_area_redraw = null;
            }
            if (legacy.reload_area_ajax) {
                legacy.reload_area_ajax?.abort();
                legacy.reload_area_ajax = null;
            }
            legacy.clear_arch_polygons();
            legacy.clear_land_polygons();
            legacy.clear_map();
            legacy.reload(false);
        } else {
            legacy.tui_base_open();
            if (legacy.dsv) legacy.dsv.blockUpdateView(false);
            if (!legacy.jQuery('#map_filter_box').is(':visible')) legacy.jQuery('#map_filter_box').css('display', '');
            legacy.jQuery(".map-name-card").css('display', '')
            overlay.current?.exitMode?.();
            overlay.current = null;

        }

    }

    const load = (payload) => {
        if (isFirstLoad.current) {
            isFirstLoad.current = false; // 첫 번째 실행을 건너뛴다
            return;
        }
        requestLoadData(payload);
        legacy.clear_arch_polygons();
        legacy.clear_land_polygons();
        legacy.clear_map();
        legacy.reload(false);
    }

    useEffect(() => {
        if (state.history == "show") {
            requestLoadCount();
            load(payload);
            window.reloadEstateManager = () => {
                requestLoadCount();
                requestLoadData(payloadRef.current);
                legacy.clear_arch_polygons();
                legacy.clear_land_polygons();
                legacy.clear_map();
                legacy.reload(false);
            };
            handleMap(true);
        } else if (state.history == "hide") {
            handleMap(false);
        }
    }, [state]);

    useEffect(() => {
        const handleResize = () => {
            setIsWebScreen(window.innerWidth > 1000);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            handleMap(false);
            window.removeEventListener('resize', handleResize);
            delete window.reloadEstateManager; // 전역 등록 해제
        };
    }, []);

    useEffect(() => {
        payloadRef.current = payload;
        load(payload);
    }, [payload])

    useEffect(() => {
        markerActiveRef.current = (!isMobile && isSideView) || (isMobile && isMapView) || (isMobile && isWebScreen);
        legacy.clear_arch_polygons();
        legacy.clear_land_polygons();
        legacy.clear_map();
        legacy.reload(false);
    }, [isSideView, isMapView])

    const fetchList = async (payload) => {
        try {
            const result = await requestEstateList(payload);
            if (result.data.length < payload.limit) setHasMore(false);
            if (isMobile) {
                if (payload.offset == 0) {
                    setData({});
                    setHasMore(true);
                }
                setData((prev) => {
                    const prevData = prev?.data || [];
                    const newData = result.data || [];

                    const updatedData = newData.reduce((acc, newItem) => {
                        const existingIndex = acc.findIndex((item) => item.suid === newItem.suid);
                        if (existingIndex !== -1) {
                            acc[existingIndex] = newItem;
                        } else {
                            acc.push(newItem);
                        }
                        return acc;
                    }, [...prevData]);

                    return {
                        ...result,
                        data: updatedData
                    };
                });
            } else {
                setData(result);
            }
        } catch (err) {

        }
    };

    const requestLoadData = (payload) => {
        const data = {
            ...payload,
            address_open: payload.address_open?.join(",") || null,
            rand_type: payload.rand_type?.join(",") || null,
            deal_type: payload.deal_type?.join(",") || null,
            dong_type: payload.location?.join(",") || null,
            sort_type: payload.order?.[0]?.type,
            sort_order: payload.order?.[0]?.order,
            limit: payload.limit?.[0]
        }
        fetchList(data);
    };

    const setEstateCount = (value, newLabel) => {
        setFilterModel((prev) => {
            const updatedOptions = prev.state.options.map((option) =>
                option.value === value ? { ...option, label: newLabel } : option
            );
            return {
                ...prev,
                state: {
                    ...prev.state,
                    options: updatedOptions
                }
            };
        });
    }

    const fetchCount = async () => {
        const sep = isMobile ? '\n' : ' '
        const CountWrapper = ({ count }) => (
            isMobile ? <span style={{ fontSize: '14px' }}>({count})</span> : <>({count})</>
        );

        try {
            const result = await requestEstateCount();
            setEstateCount('', <>{`전체${sep}`}<CountWrapper count={result.sale_total} /></>);

            if (result.sale_limit != null) {
                setEstateCount(0,
                    <>{`노출중${sep}`}
                        <CountWrapper count={`${result.sale_expose}/${result.sale_limit}`} />
                    </>
                );
            } else {
                setEstateCount(0,
                    <>{`노출중${sep}`}
                        <CountWrapper count={result.sale_expose} />
                    </>
                );
            }

            setEstateCount(3,
                <>{`비노출${sep}`}
                    <CountWrapper count={result.sale_hide} />
                </>
            );

            setEstateCount(1,
                <>{`거래완료${sep}`}
                    <CountWrapper count={result.sale_done} />
                </>
            );

            setSaleCount({
                total: result.sale_total,
                limit: result.sale_limit,
                expose: result.sale_expose,
                hide: result.sale_hide,
                done: result.sale_done
            })
        } catch (err) {

        }
    }

    const requestLoadCount = () => {
        fetchCount();
    }

    const updateAddressOpen = async (uuids, to_change) => {
        const payload = {
            uuids: uuids.join(","),
            ao: to_change
        }
        const result = await requestUpdateAddressOpen(payload);
        try {
            if (result.result == 'success') {
                setData(prev => ({
                    ...prev,
                    data: prev?.data?.map((item) =>
                        result?.effected?.includes(item.suid) ? { ...item, address_open: to_change } : item
                    )
                }))
                if (result?.effected?.length > 0) {
                    const cnt = result?.effected?.length > 1 ? `${result?.effected?.length}개 ` : '';
                    toast.info(`${cnt}매물 위치가 ${to_change == 1 ? '공개' : '비공개'} 상태로 변경되었습니다.`, {
                        position: TOAST_POSITION.BOTTOM_CENTER
                    });
                }
            } else {
                toast.error(`정보 변경에 실패하였습니다. 잠시 후 다시 시도해주세요. `, {
                    position: TOAST_POSITION.BOTTOM_CENTER
                });
            }
        } catch {
            toast.error(`서버 에러가 발생했습니다. 잠시 후 다시 시도해주세요. `, {
                position: TOAST_POSITION.BOTTOM_CENTER
            });
        }
    }

    const onUpdateAddressOpen = (uuids, to_change) => {
        updateAddressOpen(uuids, to_change);
    }

    const updateStateType = async (uuids, to_change) => {
        const payload = {
            uuids: uuids.join(","),
            state: to_change
        }
        const result = await requestUpdateStateType(payload);
        try {
            if (result.result == 'success') {
                setData(prev => ({
                    ...prev,
                    data: prev?.data?.map((item) =>
                        result?.effected?.includes(item.suid) ? { ...item, state_type: to_change } : item
                    )
                }))
                const cnt = result?.effected?.length > 1 ? `${result?.effected?.length}개 ` : '';
                let state_string = '';
                if (to_change == 0) {
                    state_string = '노출'
                } else if (to_change == 1) {
                    state_string = '거래 완료'
                } else {
                    state_string = '비노출'
                }
                toast.info(`${cnt}매물이 ${state_string} 상태로 변경되었습니다.`, {
                    position: TOAST_POSITION.BOTTOM_CENTER
                });
                requestLoadCount();
            } else if (result.result == 'over sale limit') {
                toast.error(`노출 가능한 매물 개수를 초과했습니다.`, {
                    position: TOAST_POSITION.BOTTOM_CENTER
                });
            } else {
                toast.error(`정보 변경에 실패하였습니다. 잠시 후 다시 시도해주세요. `, {
                    position: TOAST_POSITION.BOTTOM_CENTER
                });
            }
        } catch {
            toast.error(`서버 에러가 발생했습니다. 잠시 후 다시 시도해주세요. `, {
                position: TOAST_POSITION.BOTTOM_CENTER
            });
        }

    }

    const onUpdateStateType = (uuids, to_change) => {
        // 거래완료일 때 팝업창
        if (to_change == 1) {
            legacy.em_alert_popup('done', () => {
                updateStateType(uuids, to_change);
            })
            return;
        }
        updateStateType(uuids, to_change);
    }

    const updateMemo = async (uuid, memo) => {
        const payload = {
            ...memo,
            uuid: uuid
        }
        const result = await requestUpdateMemo(payload);
        try {
            if (result.result == 'success') {
                setData(prev => ({
                    ...prev,
                    data: prev?.data?.map((item) =>
                        uuid == item.suid ? { ...item, ...memo } : item
                    )
                }))
            } else {
                toast.error(`정보 변경에 실패하였습니다. 잠시 후 다시 시도해주세요. `, {
                    position: TOAST_POSITION.BOTTOM_CENTER
                });
            }
        } catch {
            toast.error(`서버 에러가 발생했습니다. 잠시 후 다시 시도해주세요. `, {
                position: TOAST_POSITION.BOTTOM_CENTER
            });
        }
    }

    const onUpdateMemo = (uuid, memo) => {
        updateMemo(uuid, memo)
    }

    const deleteSale = async (uuids) => {
        const payload = {
            uuids: uuids.join(","),
        };

        try {
            const result = await requestDeleteSale(payload);
            if (result.result === 'success') {
                load(payloadRef.current);
                if (result?.effected?.length > 0) {
                    const cnt = result?.effected?.length > 1 ? `${result?.effected?.length}개 ` : '';
                    toast.info(`${cnt}매물이 삭제되었습니다.`, {
                        position: TOAST_POSITION.BOTTOM_CENTER
                    });
                    setCheckedList((prev) => prev.filter(id => !result.effected.includes(id)));
                }
                requestLoadCount();
            } else {
                toast.error(`정보 변경에 실패하였습니다. 잠시 후 다시 시도해주세요.`, {
                    position: TOAST_POSITION.BOTTOM_CENTER
                });
            }
        } catch (error) {
            console.log(error);
            toast.error(`서버 에러가 발생했습니다. 잠시 후 다시 시도해주세요.`, {
                position: TOAST_POSITION.BOTTOM_CENTER
            });
        }
    };

    const onDeleteSale = (uuids) => {
        legacy.em_alert_popup('del', () => {
            deleteSale(uuids)
        }, { count: uuids.length })
        return;

    }

    const onDetailSaleView = (uuid) => {
        legacy.tab_sale_detail_load(uuid, true, true);
    }

    const handleMobileCheckedPopup = () => {
        setOpenCheckedController(prev => !prev);
    }

    return (
        <ScrollableFrameLayout>
            <RootFrameLayout className={`${styles.estateManageContainer} ${isMobile ? '' : styles.web}`}>

                <EstateManageHeader
                    payload={payload}
                    saleCount={saleCount}
                    onSetPayload={setPayload}
                    onResetPayload={handleResetPayload}
                    checkedList={checkedList}
                    onSetCheckedList={setCheckedList}
                    data={data}

                    filterModel={filterModel}
                    isSideView={isSideView}
                    onChangeView={setIsSideView}
                    isMapView={isMapView}
                    onChangeMapView={setMapView}

                    isMobile={isMobile}
                    isWebScreen={isWebScreen}

                    onBack={handleClosePage}

                    onUpdateAddressOpen={onUpdateAddressOpen}
                    onUpdateStateType={onUpdateStateType}
                    onDeleteSale={onDeleteSale}
                ></EstateManageHeader>

                {openMembershipGuide && !isMobile &&
                    (
                        <div className={`${styles.membershipAlert}`}>
                            <HorizontalFlex
                                leftChildren={
                                    <RowFlex gap={'0px'}>
                                        <img className={`${styles.infoIcon}`} src={icons.info.red} />
                                        <div className={`${styles.text}`}>
                                            <span>현재 {legacy.loggedInUser.getInfo().name} {legacy.loggedInUser.getInfo().position}님은 비멤버십 회원으로&nbsp;</span><span className={`${styles.accent}`}>매물이 노출되지 않습니다.</span>
                                        </div>
                                    </RowFlex>
                                }

                                rightChildren={
                                    <RowFlex className={`${styles.right}`} gap="12px">
                                        <img className={`${styles.img}`} src={icons.membership.alert} onClick={() => { legacy.readBlog('lw64tpyu'); }}></img>
                                        <img className={`${styles.close}`} src={icons.close.black} onClick={() => { setOpenMembershipGuide(false) }}></img>
                                    </RowFlex>
                                }
                            >
                            </HorizontalFlex>

                        </div>
                    )
                }
                {
                    isMobile && !isWebScreen && isMapView ?
                        <></> :
                        <AdaptiveBodyLayout
                            expand
                            view={isSideView || (isMobile && isWebScreen) ? VIEW_MODES.SIDE : VIEW_MODES.FIXED}
                            style={{ 'overflowY': 'hidden' }}
                            sticky={!(isMobile)}

                        >
                            <div className={`${styles.bodyContainer}`}>
                                <EstateManageDataTable
                                    data={data}
                                    viewType={isSideView ? DataTableViewType.LIST : DataTableViewType.TABLE}
                                    isMobile={isMobile}
                                    hasMore={hasMore}
                                    payload={payload}

                                    checkedList={checkedList}
                                    onCheckItem={handleChecked}
                                    onSetCheckedList={setCheckedList}
                                    onSetPayload={setPayload}
                                    onUpdateAddressOpen={onUpdateAddressOpen}
                                    onUpdateStateType={onUpdateStateType}
                                    onUpdateMemo={onUpdateMemo}
                                    onDeleteSale={onDeleteSale}

                                    onDetailSaleView={onDetailSaleView}
                                />
                            </div>
                            {isMobile && checkedList.length > 0 && (
                                <div className={`${styles.checkedListConrollerFromBottom} ${isSideView || (isMobile && isWebScreen) ? styles.max : ''}`}>
                                    <div className={`${styles.checkedGuideContainer}`}>
                                        <div className={`${styles.count}`}>
                                            <span className={`${styles.strong}`}>{checkedList.length}개</span>
                                            &nbsp;선택됨
                                        </div>
                                        <button onClick={() => { setCheckedList([]) }}>
                                            <img src={icons.close.black}></img>
                                        </button>
                                    </div>
                                    <div className={`${styles.buttonContainer}`}>
                                        <button className={`${styles.button}`} onClick={handleMobileCheckedPopup}>
                                            <img src={icons.saleState.visible.off}></img>
                                            <div>매물상태 변경</div>
                                        </button>
                                        <button className={`${styles.button}`} onClick={() => { onDeleteSale(checkedList) }}>
                                            <img src={icons.delete.grey}></img>
                                            <div>삭제하기</div>
                                        </button>
                                    </div>
                                </div>
                            )
                            }
                        </AdaptiveBodyLayout>
                }
                <ModelPopup
                    isOpen={isMobile && openCheckedController}
                    position={"absolute"}
                    onClose={handleMobileCheckedPopup}
                >
                    <div className={`${styles.checkedControlModal}`}>
                        <HorizontalFlex
                            className={styles.header}
                            leftChildren={
                                <>
                                    <div className={styles.title}>매물상태 변경</div>
                                </>
                            }
                            rightChildren={
                                <div className={styles.close} onClick={handleMobileCheckedPopup}>
                                    <img src={icons.close.black} width={24} height={24} />
                                </div>
                            }
                        />
                        <VerticalFlex>
                            <button className={`${styles.button}`} onClick={() => { onUpdateStateType(checkedList, 0) }}>
                                <img src={icons.saleState.visible.off} />
                                <div>매물 노출</div>
                            </button>
                            <button className={`${styles.button}`} onClick={() => { onUpdateStateType(checkedList, 3) }}>
                                <img src={icons.saleState.invisible.off} />
                                <div>매물 비노출</div>
                            </button>
                            <button className={`${styles.button}`} onClick={() => { onUpdateAddressOpen(checkedList, 1) }}>
                                <img src={icons.saleLocation.public} />
                                <div>위치 공개</div>
                            </button>
                            <button className={`${styles.button}`} onClick={() => { onUpdateAddressOpen(checkedList, 0) }}>
                                <img src={icons.saleLocation.private} />
                                <div>위치 비공개</div>
                            </button>
                        </VerticalFlex>
                    </div>



                </ModelPopup>
            </RootFrameLayout>
            <ToastContainer />

        </ScrollableFrameLayout>
    );
};

export default EstateManagePage;
