export const ifo_get_land_purpose_type = window.ifo_get_land_purpose_type;
export const estateMarkerAjax = window.estateMarkerAjax;
export const clear_map = window.clear_map;
export const reload = window.reload;
export const DiscoSubPageMode = window.DiscoSubPageMode;
export const DiscoSubPageModeParams = window.DiscoSubPageModeParams;
export const g_price_markers = window.g_price_markers;
export const em_click_slot = window.em_click_slot;
export const get_rev_xy = window.get_rev_xy;
export const get_pnu_by_latlng = window.get_pnu_by_latlng;
export const filter_compare = window.filter_compare;
export const map_none_ads_marker = window.map_none_ads_marker;
export const map_sale_default_marker_sale = window.map_sale_default_marker_sale;
export const map_auction_default_marker = window.map_auction_default_marker;
export const daum = window.daum;
export const map = window.map;
export const timer_redraw = window.timer_redraw;
export const timer_area_redraw = window.timer_area_redraw;
export const redraw = window.redraw;
export const clear_arch_polygons = window.clear_arch_polygons;
export const clear_land_polygons = window.clear_land_polygons;
export const toast = window.toast;
export const jQuery = window.$;
export const to_area = window.to_area;
export const timer_redraw_overlay_cluster = window.timer_redraw_overlay_cluster;
export const bh = window.bh;
export const tui_base_close = window.tui_base_close;
export const dsv = window.dsv;
export const tui_base_open = window.tui_base_open;



const legacy = window;
export default legacy;