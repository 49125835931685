import React from "react";
import icons from "../../assets/images/icons";
import styles from './buttons.module.scss'

const DefaultBackbutton = ({
    className="",
    onClick = () => { },
    size = 24
}) => {

    return (
        <div className={`${styles.button} ${className}`}onClick={onClick}>
            <img src={icons.back.black} width={size} height={size}></img>
        </div>
    )
}

export { DefaultBackbutton }



