import React, { useEffect, useState } from "react";
import styles from "./Tab.module.scss";
import { RowFlex } from "../flex/RowFlex";

const ImageLabelTab = ({
    className = "",
    options = [],
    selected = null,
    onChange = () => { },
    style={}
}) => {
    const [selectedValue, setSelectedValue] = useState(selected || options[0]?.value);
    const handleSelect = (value) => {
        setSelectedValue(value);

    };
    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue])
    const getIcon = (option, isSelected) => {
        return (
            <img
                src={isSelected ? option.icon.selected : option.icon.default}
                alt={option.label}
            />
        );
    };
    return (
        <RowFlex className={`${styles.imageLabelTabContainer} ${className}`} style={{...style}}>
            {options.map((option, index) => {
                const isSelected = selectedValue === option.value;
                return (
                    <button
                        key={index}
                        value={option.value}
                        className={`${styles.tab} ${isSelected ? styles.selected : ""}`}
                        onClick={() => handleSelect(option.value)}
                    >
                        {getIcon(option, isSelected)}
                        <div>{option.label}</div>
                    </button>
                );
            })}
        </RowFlex>
    );
};

const LabelTab =  ({
    className = "",
    options = [],
    selected = null,
    onChange = () => { },
    style={}
}) => {
    const [selectedValue, setSelectedValue] = useState(selected || options[0]?.value);
    const handleSelect = (value) => {
        setSelectedValue(value);

    };
    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue])
    return (
        <RowFlex className={`${styles.LabelTabContainer} ${className}`} style={{...style}}>
            {options.map((option, index) => {
                const isSelected = selectedValue === option.value;
                return (
                    <button
                        key={index}
                        value={option.value}
                        className={`${styles.tab} ${isSelected ? styles.selected : ""}`}
                        onClick={() => handleSelect(option.value)}
                    >
                        <div>{option.label}</div>
                    </button>
                );
            })}
        </RowFlex>
    );
};

const NormalTab = ({
    className = "",
    options = [],
    selected = null,
    onChange = () => { },
    style={}
}) => {

    const [selectedValue, setSelectedValue] = useState(selected || options[0]?.value);

    const handleSelect = (value) => {
        setSelectedValue(value);
    };

    useEffect(() => {
        onChange(selectedValue);
    }, [selectedValue])

    return (
        <RowFlex className={`${styles.normalTabContainer} ${className}`} style={{...style}}>
            {options.map((option, index) => {
                const isSelected = selectedValue === option.value;
                return (
                    <button
                        key={index}
                        value={option.value}
                        className={`${styles.tab} ${isSelected ? styles.selected : ""}`}
                        onClick={() => handleSelect(option.value)}
                    >
                        <div>{option.label}</div>
                    </button>
                );
            })}
        </RowFlex>
    )
};

export { ImageLabelTab ,  LabelTab, NormalTab};
