import { getData, postData } from "../baseApi";
import { jsonToForm } from "../utils";

const ESTATE_API_ENDPOINT = {
    LIST: "/sale/get_my_estate_list/",
    COUNT : "/sale/get_my_estate_count/",
    MARKER : '/sale/get_estate_marker_list/',
    UPDATE_STATE: "/sale/update_estate_state_type/",
    UPDATE_ADDRESS_OPEN: "/sale/update_estate_address_open/",
    UPDATE_MEMO : '/sale/update_estat_memo_data/',
    DELETE_SALE : '/sale/delete_for_sales/'
};

export const requestEstateList = (payload) => {
    const form = jsonToForm(payload);
    return postData(ESTATE_API_ENDPOINT.LIST, form);
};

export const requestEstateCount = () => {
    return getData(ESTATE_API_ENDPOINT.COUNT);
};

export const requestEstateMarker = (payload) => {
    const form = jsonToForm(payload);
    return postData(ESTATE_API_ENDPOINT.MARKER, form);
};

export const requestUpdateStateType = (payload) => {
    const form = jsonToForm(payload);
    return postData(ESTATE_API_ENDPOINT.UPDATE_STATE, form);
};

export const requestUpdateAddressOpen = (payload) => {
    const form = jsonToForm(payload);
    return postData(ESTATE_API_ENDPOINT.UPDATE_ADDRESS_OPEN, form);
};

export const requestUpdateMemo = (payload) => {
    const form = jsonToForm(payload);
    return postData(ESTATE_API_ENDPOINT.UPDATE_MEMO, form);
};



export const requestDeleteSale = (payload) => {
    const form = jsonToForm(payload);
    return postData(ESTATE_API_ENDPOINT.DELETE_SALE, form);
};
