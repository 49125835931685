import React from "react";
import styles from "./Pagination.module.scss"; // 🔹 SCSS 파일을 사용하여 스타일링
import icons from "../../assets/images/icons";

const Pagination = ({
  totalPages = 1,
  currentPage = 1,
  onPageChange = () => { },
  maxPageNumbers=10
}) => {
  if (totalPages <= 1) return null;

  const pageNumbers = [];
  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  if (endPage - startPage + 1 < maxPageNumbers) {
    startPage = Math.max(1, endPage - maxPageNumbers + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={styles.paginationContainer}>
      <button
        className={`${styles.pageButton} ${styles.arrow}`}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        <img src={icons.arrow.left.black}/>
      </button>

      {pageNumbers.map((page) => (
        <button
          key={page}
          className={`${styles.pageButton} ${currentPage === page ? styles.active : ""}`}
          onClick={() => onPageChange(page)}
        >
          {page}
        </button>
      ))}

      <button
        className={`${styles.pageButton} ${styles.arrow}`}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        <img src={icons.arrow.right.black}/>
      </button>
    </div>
  );
};

export default Pagination;
