import React from "react";
import styles from "./flex.module.css";

const HorizontalFlex = ({ 
    leftChildren, 
    rightChildren, 
    children, 
    className = "", 
    leftParentClassName="",
    rightParentClassName="",
    gap = '0px' }) => {
    return (
        <div className={`${styles.flexContainer} ${className}`} style={{gap : gap}}>
            {(leftChildren || children) && <div className={`${styles.leftContent} ${leftParentClassName}` } >{leftChildren}{children}</div>}
            { rightChildren && <div className={`${styles.rightContent} ${rightParentClassName}`}>{rightChildren}</div>}
        </div>
    );
};

export { HorizontalFlex };
