import React from "react";
import styles from "./flex.module.css";

const VerticalFlex = ({ className = "", gap='0px', children }) => {
    return (
        <div className={`${styles.flexContainer} ${styles.vertical} ${className}`} style={{gap : gap}}>
            {React.Children.map(children, (child) =>
                React.isValidElement(child) && child.props.expand
                    ? React.cloneElement(child, { className: `${styles.expandingChild} ${child.props.className || ""}` })
                    : child
            )}
        </div>
    );
};

export { VerticalFlex };
