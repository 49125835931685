import React from "react";
import styles from "./inputText.module.scss";

export function DefaultInputText({
  label = "",
  type = "text",
  placeholder = "",
  value,
  onChange = () => {},
  name,
  disabled = false,
  multiline = false,
  rows = 4,
}) {
  const handleChange = (e) => {
    onChange?.(e.target.value);
  };

  const allowedTypes = ["text", "number", "email", "password"];
  const inputType = allowedTypes.includes(type) ? type : "text";
  const generateInputName = (label) =>
    label
      ? label.replace(/[^\w가-힣]/g, "_")
      : "input";

  const inputName = name || generateInputName(label);

  return (
    <div className={styles.defaultInput}>
      {label && <label className={styles.label} htmlFor={inputName}>{label}</label>}
      {multiline ? (
        <textarea
          className={styles.input}
          id={inputName}
          name={inputName}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          rows={rows}
        />
      ) : (
        <input
          className={styles.input}
          type={inputType}
          id={inputName}
          name={inputName}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      )}
    </div>
  );
}

