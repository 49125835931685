export function dealNameColor(name) {
    if (name == '매매') return '#1263df';
    else if (name == '전세') return '#FF6996';
    else if (name == '월세') return '#8750FF';
    else if  (name == '시세') return '#00C8B4';
    return '#060606';
  }

  export function realPriceColor(type) {
    const output = (color, bgColor) => {
      return { color : color , backgroundColor : bgColor };
    }
    if (type == '단독')
      return output("#a47113" , "#fef8e6");
    if (type == '토지')
      return output("#5e9100" , "#f3fae6");
    if (type == '다세대')
      return output("#fd5f06" , "#ffefe6");
    if (type == '아파트')
      return output("#099d89" , "#e9faf7");
    if (type == '오피스텔')
      return output("#128cda" , "#ebf7ff");
    if (type == '상업용건물')
      return output("#3f32e2" , "#f0eeff");
    if (type == '주상복합')
      return output("#099d89" , "#e9faf7");
    if (type == '상가사무실' || type == '상가' || type == '사무실')
      return output("#ab35ff" , "#f7ebff");
    if (type == '공장/창고')
      return output("#745147" , "#f4f1f0");
    if (type == '다가구')
      return output("#a47113" , "#fef8e6");
    if (type == '연립' || type == '연립주택')
      return output("#fd5f06" , "#ffefe6");
    if (type == '숙박시설')
      return output("#faebf7" , "#d136af");
    if (type == '분양권')
      return output("#245ed7" , "#f0f5ff");
    return output("#0868f4" , "#edf4ff");
  }