import React, { useEffect, useState, useRef, useCallback, useId } from "react";
import styles from "./EstateManage.module.scss";
import { VerticalFlex } from "../../components/flex/VerticalFlex";
import { priceFormat } from "../../utils/format";
import { dealNameColor, realPriceColor } from "../../utils/color";
import Pagination from "../../components/table/Pagination";
import { RowFlex } from "../../components/flex/RowFlex";
import icons from "../../assets/images/icons";
import { HorizontalFlex } from "../../components/flex/HorizontalFlex";
import { DefaultInputText } from "../../components/input/InputText";
import { CONFIRM_BUTTON_THEME, DefaultConfirmButton } from "../../components/buttons/ConfirmButton";
import legacy from '../../utils/legacy';
import { createPortal } from "react-dom";


export const DataTableViewType = {
    TABLE: 'table',
    LIST: 'list',
}
const EstateManageDataTable = ({
    data = {},
    checkedList = [],
    viewType = DataTableViewType.TABLE,
    isMobile = false,
    hasMore = false,
    payload = {},
    onCheckItem = () => { },
    onSetCheckedList = () => { },
    onSetPayload = () => { },
    onUpdateAddressOpen = () => { },
    onUpdateStateType = () => { },
    onUpdateMemo = () => { },
    onDeleteSale = () => {},

    onDetailSaleView = () => { }
}) => {


    const tableContainerRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);
    const scrollInterval = useRef(null);
    const isManualScroll = useRef(false);


    const SCROLL_STEP = 150;
    const columns = [
        { label: "", key: 'checkbox', fixed: true, onClick: () => { } },
        { label: "유형", key: 'type' },
        { label: "매물사진", key: 'photo' },
        { label: "금액", key: 'price' },
        { label: "주소", key: 'address' },
        { label: "날짜", key: 'date' },
        { label: "노출 설정", key: 'control' },
        { label: "부가정보 관리", key: 'addition' },
        { label: "관리", key: 'manage', fixed: true }
    ]

    const handleChecked = (val) => {
        onCheckItem(val)
    }

    const handlePayload = (key, value) => {
        onSetPayload(prev => {
            if (key === 'offset') {
                const newOffset = prev.limit ? prev.limit[0] * value : value;
                if (prev.offset !== newOffset) {
                    return {
                        ...prev,
                        offset: newOffset
                    };
                }
                return prev;
            }

            if (prev[key] !== value) {
                return {
                    ...prev,
                    [key]: value
                };
            }

            return prev;
        });
    };


    const getIsAllChecked = () => {
        const allSuidList = data?.data?.map(item => item.suid) || [];
        const isAllChecked = allSuidList.every(suid => checkedList.includes(suid)) && checkedList.length > 0;
        return isAllChecked;
    }

    const handleCheckAll = () => {
        const allSuidList = data?.data?.map(item => item.suid) || [];
        const isAllChecked = allSuidList.every(suid => checkedList.includes(suid));
        if (isAllChecked) {
            onSetCheckedList(prev => prev.filter(suid => !allSuidList.includes(suid)));
        } else {
            onSetCheckedList(prev => [...new Set([...prev, ...allSuidList])]);
        }
    }


    useEffect(() => {
        const updateScrollButtons = () => {
            if (!tableContainerRef.current) return;
            const { scrollLeft, scrollWidth, clientWidth } = tableContainerRef.current;
            setCanScrollLeft(scrollLeft > 0);
            setCanScrollRight(scrollLeft < scrollWidth - clientWidth -1);
        };

        const attachScrollListener = () => {
            if (tableContainerRef.current) {
                updateScrollButtons();
                tableContainerRef.current.addEventListener('scroll', updateScrollButtons);
            }
        };

        attachScrollListener();
        window.addEventListener('resize', updateScrollButtons);

        const observer = new MutationObserver(() => {
            attachScrollListener();
        });
        observer.observe(document.body, { childList: true, subtree: true });

        return () => {
            if (tableContainerRef.current) {
                tableContainerRef.current.removeEventListener('scroll', updateScrollButtons);
            }
            window.removeEventListener('resize', updateScrollButtons);
            observer.disconnect();
        };
    }, [tableContainerRef.current]);

    const scrollToEnd = (direction) => {
        if (tableContainerRef.current) {
            if (direction === 'left') {
                tableContainerRef.current.scrollTo({ left: 0, behavior: 'smooth' });
            } else {
                tableContainerRef.current.scrollTo({ left: tableContainerRef.current.scrollWidth, behavior: 'smooth' });
            }
        }
    };

    const loadMoreRef = useRef(null);
    useEffect(() => {
        if (!isMobile) return;
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && hasMore) {
                    handlePayload('offset', data.offset + 1);
                }
            },
            { threshold: 1.0 }
        );
        if (loadMoreRef.current) observer.observe(loadMoreRef.current);
        return () => observer.disconnect();
    }, [data?.offset, hasMore]);

    const renderTable = () => {
        return (
            <div style={{    position: 'relative', overflow: 'hidden', width: '100%', flexGrow: '1', paddingLeft : '20px'}}>

            <div ref={tableContainerRef} className={`${styles.tableContainer} ${styles.tableItemStyles}`} expand={true}>
                <table>
                    <thead className={`${styles.stickyHeader}`}>
                        <tr>
                            {columns.map((header) => {
                                return header.key == "checkbox" ? (
                                    <th height="48" key={header.key} className={`${header.fixed ? styles.fixedCell : ""}`} onClick={() => handleCheckAll()}>
                                        <CheckboxCell
                                            key={`${header.key}_box`}
                                            checked={getIsAllChecked()}
                                        />
                                    </th>
                                ) :
                                    (
                                        <th height="48" key={header.key} className={`${header.fixed ? styles.fixedCell : ""}`}>
                                            {header.label}
                                        </th>
                                    )
                            }
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.data?.length > 0 ?
                                data?.data?.map((row, index) => (
                                    <RenderRow
                                        key={index}
                                        data={row}
                                        columns={columns}
                                        checkedList={checkedList}
                                        onCheckItem={handleChecked}
                                        onUpdateAddressOpen={onUpdateAddressOpen}
                                        onUpdateStateType={onUpdateStateType}
                                        onUpdateMemo={onUpdateMemo}
                                        onDetailSaleView={onDetailSaleView}
                                        onDeleteSale={onDeleteSale}
                                    ></RenderRow>
                                )) :
                                <tr className={`${styles.notaction}`}>
                                    <td colSpan={columns.length} className={styles.noData} style={{ textAlign: "center" }}>
                                        <img src={icons.saleNoData} style={{ width: '80px', height: '80px' }} alt="No Data" />
                                        {
                                            payload.keyword ?
                                                <div>
                                                    <p><span style={{ color: '#0868f4', fontWeight: 'bold' }}>{payload.keyword}</span>에 대한 검색결과가 없어요!</p>
                                                    <p>혹시 검색어를 다시 확인해 보시겠어요?</p>
                                                </div>
                                                :
                                                <p>등록된 매물이 없습니다.</p>
                                        }
                                    </td>
                                </tr>
                        }
                    </tbody>
                </table>

            </div>
            {canScrollLeft && (
                <div className={`${styles.scrollBtnContainer} ${styles.left}`} >
                    <button className={`${styles.scrollBtn}`} 
                        onClick={() => scrollToEnd('left')} >
                        <img src={icons.squareArrow.left.black}></img>
                    </button>
                </div>
            )}
            {canScrollRight && (
                    <div className={`${styles.scrollBtnContainer} ${styles.right}`}  > 
                        <button className={`${styles.scrollBtn}`}  
                            onClick={() => scrollToEnd('right')} >
                            <img src={icons.squareArrow.right.black}></img>
                        </button>
                    </div>
            )}
            </div>
        )
    }

    const renderList = () => {
        return (
            <div className={`${styles.listContainer} ${styles.listItemStyles} ${isMobile ? styles.bottomPadding : ''}`} expand={true}>
                {
                    data?.data?.length > 0 ?
                        data?.data?.map((row, index) => (
                            <RenderItem
                                key={index}
                                data={row}
                                columns={columns}
                                checkedList={checkedList}
                                onCheckItem={handleChecked}
                                onUpdateAddressOpen={onUpdateAddressOpen}
                                onUpdateStateType={onUpdateStateType}
                                onUpdateMemo={onUpdateMemo}
                                onDeleteSale={onDeleteSale}
                                onDetailSaleView={onDetailSaleView}
                                isMobile={isMobile}
                            ></RenderItem>
                        )) :
                        <div className={`${styles.notaction} ${styles.listNoData}`}>
                            <div className={styles.noData} style={{ textAlign: "center" }}>
                                <img src={icons.saleNoData} style={{ width: '80px', height: '80px' }} alt="No Data" />
                                {
                                    payload.keyword ?
                                        <div className={`${styles.wrap}`}>
                                            <div>
                                                <div style={{ color: '#0868f4' }}>{payload.keyword}</div>
                                                <div style={{ marginTop: '4px' }}>에 대한 검색결과가 없어요!</div>
                                            </div>
                                            <div className={`${styles.nText}`}>검색어를 다시 확인해 보시겠어요?</div>
                                        </div>
                                        :
                                        <div>등록된 매물이 없습니다.</div>
                                }

                            </div>
                        </div>
                }
                {isMobile && hasMore && <div ref={loadMoreRef} className={styles.loadMoreTrigger}></div>}
            </div>
        )

    }

    return (
        data?.data &&
        <VerticalFlex className={`${styles.dataContainer} ${viewType == DataTableViewType.TABLE && !isMobile ? styles.fullMaxSize : ''}`}>
            {
                viewType == DataTableViewType.TABLE && !isMobile ?
                    renderTable() : renderList()
            }
            {!isMobile && (
                <div className={`${styles.paginationContainer}`}>
                    <Pagination
                        totalPages={data.pages}
                        currentPage={data.offset + 1}
                        onPageChange={(idx) => handlePayload('offset', idx - 1)}
                        maxPageNumbers={viewType === DataTableViewType.TABLE ? 10 : 5}
                    />
                </div>
            )}
        </VerticalFlex>
    );
}

export { EstateManageDataTable }

const CheckboxCell = ({ name, value, checked }) => {
    return (
        <div className={styles.checkboxWrapper}>
            <div className={`${styles.checkbox} ${checked ? styles.checked : ''}`}></div>
        </div>
    )
};

const TypeCell = ({ data }) => {
    const getState = (data) => {
        if (data.state_type == 0) {
            if (data.ad == 1) {
                return (<div className={`${styles.typeItem} ${styles.state} ${styles.ad}`}><div>&#x2022;</div>광고중</div>);
            }
            return (<div className={`${styles.typeItem} ${styles.state} ${styles.release}`}><div>&#x2022;</div><div>노출중</div></div>);
        } else if (data.state_type == 1) {
            return (<div className={`${styles.typeItem} ${styles.state} ${styles.done}`}><div>거래완료</div></div>);
        } else {
            return (<div className={`${styles.typeItem} ${styles.state} ${styles.hide}`}><div>비노출</div></div>);
        }
    }


    return (
        <div className={styles.typeCell}>
            {getState(data)}
            <div className={`${styles.typeItem}`} style={{ ...realPriceColor(legacy.real_price_type(data.estate_type))} } >{legacy.ifo_get_rand_type(data.e_rand_type)}</div>
        </div>

    )
}

const getPhotoUrl = (photoUrl) => {
    if (!photoUrl) return null;
    const photos = photoUrl.split('|');
    for (let i = 0; i < photos.length; i++) {
        if (photos[i].indexOf('http') < 0) {
            return legacy.server_by_hostname('aurora', "files/sale_photo/72x72_") + photos[i];
        }
    }
    return null;
}

const PhotoCell = ({ photoUrl }) => {
    const photo = getPhotoUrl(photoUrl);
    return (
        photo ? (
            <div className={styles.photoCell} style={{ backgroundImage: `url(${getPhotoUrl(photoUrl)}` }} />
        ) : (
            <div className={`${styles.photoCell} ${styles.blank}` }  style={{ backgroundImage: `url(${icons.blankImage.sale}` }} />
        )
        
    )
}

const PriceCell = ({ dealType, price, deposit, monthly }) => {
    const dealName = ["매매", "전세", "월세"][dealType] || "미등록";
    const dealColor = dealNameColor(dealName);
    const formattedPrice = price ? priceFormat(price) : monthly > 0 ? `${priceFormat(deposit)} / ${priceFormat(monthly)}` : "미등록";
    return (
        <div className={styles.priceCell}>
            <div className={styles.dealName} style={{ color: dealColor }}>{dealName}</div>
            <div className={styles.price}>{formattedPrice}</div>
        </div>
    );
};

const AddressCell = ({ address, buildingName, data }) => {

    const getConinfo = (data) => {
        const to_area = legacy.to_area;
        var con_info = '';
        if (data['e_rand_type'] === 16) {
            con_info += to_area(data['land_area']) + " ・ " + legacy.ifo_get_land_purpose_type(data['land_purpose']);
        } else if (
            [0, 1, 2, 7, 8, 10, 11, 18].indexOf(data['e_rand_type']) !== -1
            || ([4, 6].indexOf(data['e_rand_type']) !== -1 && data['deal_type'] !== 0)
        ) {
            var floor = '';
            if (data['floor_type'] == null || data['floor_type'] < 0) {
                floor = data['floor_cnt'] + " / " + data['floor_total'];
            } else {
                if (data['floor_type'] == 0) floor = '고';
                if (data['floor_type'] == 1) floor = '중';
                if (data['floor_type'] == 2) floor = '저';
            }

            var area_nm = ([2, 7, 8, 10, 11, 18].indexOf(data['e_rand_type']) !== -1) ? '계약 ' : '공급 ';
            con_info += area_nm + to_area(data['supply_area']) + " ・  전용 " + to_area(data['floor_area']) + " ・ " + floor + "층";
        } else {
            con_info += "대지 " + to_area(data['land_area']) + " ・ 연 " + to_area(data['floor_area']) + " ・ " + data['floor_cnt'] + "F / B" + data['under_floor'];
        }
        return con_info;
    }
    return (
        <div className={styles.addressCell}>
            {/* <div className={styles.buildingName}>{address} {buildingName && `(${buildingName})`}</div> */}
            <div className={styles.buildingName}>{address}</div>
            <div className={styles.details} dangerouslySetInnerHTML={{ __html: getConinfo(data) }}></div>
        </div>
    )
}

const DateCell = ({ creationDate }) => {
    const [year, month, day] = creationDate.split(".");
    return (
        <div className={styles.dateCell}>
            <DateDiifCell creationDate={creationDate}></DateDiifCell>
            <div className={styles.shortDate}>{`${year.slice(2)}/${month}/${day}`}</div>
        </div>
    );
};

const DateDiifCell = ({ creationDate }) => {
    return (
        <div className={styles.agoDate}>{legacy.build_due_str_for_creation(creationDate)?.[0]}</div>
    );
}

const ToggleButton = ({ title, checked, onToggle, disabled = false }) => {
    const toggleId = useId(); // 고유한 id 생성 (React 18+)

    return (
        <div className={`${styles.toogleSwitchContainer}`} onClick={(e) => { e.stopPropagation(); }}>
            <input
                id={toggleId}
                type="checkbox"
                checked={checked}
                readOnly
            />
            <label htmlFor={toggleId} className={`${styles.toggleSwitch} ${disabled ? styles.disabled : ''}`} onClick={() => { if (!disabled) { onToggle(!checked); } }} >
                <span>{checked ? title.checked : title.default}</span>
                <span className={styles.slider}></span>
            </label>
        </div>
    )
};

const AdditionButton = ({ title, icon, deactive, children, onToggle }) => {
    const [openPopup, setOpenPopup] = useState(false);
    const popupRef = useRef(null);
    const handleClick = (e) => {
        e.stopPropagation();
        setOpenPopup(true);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopup(false);
            }
        };

        if (openPopup) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }

        onToggle?.(openPopup);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }
    }, [openPopup]);

    const closePopup = () => {
        setOpenPopup(false);
    };

    return (
        <div className={`${styles.additionButtonWrapper}`} >
            <button className={`${styles.additionButton} ${!deactive ? styles.active : ''}`} onClick={handleClick}>
                <img src={icon} alt="icon" />
                <div className={`${styles.label}`}>{title}</div>
            </button>

            {openPopup && (
                <div ref={popupRef} className={`${styles.additionButtonPopup}`} onClick={(e) => e.stopPropagation()} >
                    <HorizontalFlex
                        className={`${styles.header}`}
                        leftChildren={(
                            <div style={{ marginTop: '8px' }}>{title}</div>
                        )}
                        rightChildren={(
                            <div className={styles.cursorPointer} style={{ padding: '12px' }} onClick={closePopup}>
                                <img src={icons.close.black} style={{ width: '24px', height: '24px' }}></img>
                            </div>
                        )}
                    />
                    {children}
                </div>
            )}
        </div>
    );
}

const MemoEditor = ({ onClick, data }) => {
    const confirmStyle = { width: '100%', fontSize: '16px', fontWeight: 'normal', padding: '14px 0px' }

    const getInitial = () => ({
        sell_name: data.sell_name || "",
        sell_phone: data.sell_phone || "",
        buy_name: data.buy_name || "",
        buy_phone: data.buy_phone || "",
        memo: data.memo || ""
    })


    const initialMemo = getInitial();

    const [memo, setMemo] = useState(initialMemo)
    const [isChanged, setIsChanged] = useState(false);

    const handleChange = (field, value) => {
        setMemo(prev => ({ ...prev, [field]: value }));
    };

    const handleDeleteMemo = () => {
        setMemo({
            sell_name: "",
            sell_phone: "",
            buy_name: "",
            buy_phone: "",
            memo: ""
        });
    };

    const handleSave = () => {
        onClick?.(data.suid, memo);
    }

    useEffect(() => {
        setIsChanged(JSON.stringify(memo) !== JSON.stringify(initialMemo));
    }, [memo, initialMemo]);

    return (
        <div className={`${styles.body}`}>
            <VerticalFlex gap="12px">
                <RowFlex gap="12px">
                    <DefaultInputText
                        label={'매도/임대인'}
                        placeholder={"홍길동"}
                        value={memo.sell_name}
                        onChange={(val) => handleChange("sell_name", val)}
                    />
                    <DefaultInputText
                        label={'전화번호'}
                        placeholder={"010-0000-0000"}
                        value={memo.sell_phone}
                        onChange={(val) => handleChange("sell_phone", val)}
                    />
                </RowFlex>
                <RowFlex gap="12px">
                    <DefaultInputText
                        label={'매수/임차인'}
                        placeholder={"홍길동"}
                        value={memo.buy_name}
                        onChange={(val) => handleChange("buy_name", val)}
                    />
                    <DefaultInputText
                        label={'전화번호'}
                        placeholder={"010-0000-0000"}
                        value={memo.buy_phone}
                        onChange={(val) => handleChange("buy_phone", val)}
                    />
                </RowFlex>
                <DefaultInputText
                    label={'메모'}
                    placeholder={"메모"}
                    multiline={true}
                    value={memo.memo}
                    onChange={(val) => handleChange("memo", val)}
                />
                <RowFlex gap="8px" style={{ marginTop: '4px' }}>
                    <DefaultConfirmButton
                        theme={CONFIRM_BUTTON_THEME.GREY}
                        label={"메모 삭제"}
                        style={confirmStyle}
                        onClick={handleDeleteMemo}
                    />
                    <DefaultConfirmButton
                        label={"저장"}
                        style={confirmStyle}
                        disabled={!isChanged}
                        onClick={handleSave}
                    />
                </RowFlex>
            </VerticalFlex>
        </div>
    );
}
const AdditionButtonsMemo = ({ title, icon, onClick, deactive, data }) => {
    return (
        <AdditionButton title={title} icon={icon} deactive={deactive}>
            <MemoEditor
                data={data}
                onClick={onClick}
            />
        </AdditionButton>
    );
}

const AdditionButtonsReport = ({ title, icon, onClick, deactive, data }) => {
    return (
        <div className={`${styles.additionButtonWrapper}`} >
            <button className={`${styles.additionButton} ${!deactive ? styles.active : ''}`} onClick={(e) => { e.stopPropagation(); onClick?.() }}>
                <img src={icon} alt="icon" />
                <div className={`${styles.label}`}>{title}</div>
            </button>
        </div>
    )
}


const ManageCell = ({ items, style = {} }) => {
    const [openPopup, setOpenPopup] = useState(false);
    const popupRef = useRef(null);
    const buttonRef = useRef(null);
    const [popupStyle, setPopupStyle] = useState({});

    const updatePopupPosition = () => {
        if (buttonRef.current && popupRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            const popupWidth = popupRef.current.offsetWidth || 0;
            const popupHeight = popupRef.current.offsetHeight || 0;
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;
            const shouldPlaceOnTop = rect.bottom + popupHeight + 10 > windowHeight;

            setPopupStyle({
                position: "fixed",
                top: shouldPlaceOnTop ? `${rect.top - popupHeight - 10}px` : `${rect.bottom + 10}px`,
                left: rect.right + popupWidth > windowWidth ? `${rect.right - popupWidth}px` : `${rect.right - popupWidth}px`,
                maxHeight: shouldPlaceOnTop ? `${rect.top - 10}px` : `${windowHeight - rect.bottom - 10}px`,
                overflowY: "auto",
            });
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        setOpenPopup(prev => {
            const newState = !prev;
            if (newState) {
                setTimeout(updatePopupPosition, 0);
            }
            return newState;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setOpenPopup(false);
            }
        };

        const handleScroll = () => {
            setOpenPopup(false);
        };

        if (openPopup) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
            window.addEventListener("scroll", handleScroll, true);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
            window.removeEventListener("scroll", handleScroll, true);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, [openPopup]);

    const handleItemClick = (e, item) => {
        e.stopPropagation();
        setOpenPopup(false);
        item.onClick?.(e);
    };

    const popupContent = (
        <div ref={popupRef} className={styles.mcDropDownContainer} style={popupStyle}>
            {items.map((item, idx) => (
                <div
                    key={idx}
                    className={styles.item}
                    onClick={(e) => handleItemClick(e, item)}
                >
                    {item.icon && <img className={styles.itemIcon} src={item.icon} alt={item.label} />}
                    <div>{item.label}</div>
                </div>
            ))}
        </div>
    );

    return (
        <div className={`${styles.manageCell} ${openPopup ? styles.active : ''}`} style={{ ...style }}>
            <button className={`${styles.icon} ${styles.cursorPointer}`} onClick={handleClick} ref={buttonRef}>
                <img src={openPopup ? icons.menu.horizontal.blue : icons.menu.horizontal.black}></img>
            </button>
            {openPopup && createPortal(popupContent, document.body)}
        </div>
    );
};

const RenderRow = ({
    data,
    columns,
    checkedList,
    onCheckItem,
    onUpdateAddressOpen,
    onUpdateStateType,
    onUpdateMemo,
    onDeleteSale,
    onDetailSaleView
}) => {

    const handleCheckItem = useCallback((e) => {
        e.stopPropagation();
        onCheckItem(data.suid);
    }, [data.suid, onCheckItem]);

    return (
        <tr key={data.suid} onClick={() => { onDetailSaleView(data.suid) }}
            className={`
                  ${checkedList.includes(data.suid) ? styles.checkedItem : ''}
                  ${data.state_type === 1 ? styles.doneItem : ''}
            `}
        >
            {columns.map((column) => {
                switch (column.key) {
                    case "checkbox":
                        return (
                            <td key={`${data.suid}_${column.key}`} className={styles.fixedCell} onClick={handleCheckItem}>
                                <CheckboxCell
                                    value={data.suid}
                                    checked={checkedList.includes(data.suid)}
                                />
                            </td>
                        );
                    case "type":
                        return (
                            <td key={`${data.suid}_${column.key}`}><TypeCell data={data} /></td>
                        );
                    case "photo":
                        return <td key={`${data.suid}_${column.key}`}><PhotoCell photoUrl={data.photo} /></td>;
                    case "price":
                        return <td key={`${data.suid}_${column.key}`}><PriceCell dealType={data.deal_type} price={data.price} deposit={data.deposit} monthly={data.monthly} /></td>;
                    case "address":
                        return <td key={`${data.suid}_${column.key}`}><AddressCell address={data.address} buildingName={data.building_name} data={data} /></td>;
                    case "date":
                        return <td key={`${data.suid}_${column.key}`}><DateCell creationDate={data.creation_date} /></td>;
                    case "control":
                        return (
                            <td key={`${data.suid}_${column.key}`}>
                                <div className={styles.controlContainer}>
                                    <ToggleButton
                                        title={{ checked: "매물 노출", default: "매물 비노출" }}
                                        checked={data.state_type === 0}
                                        disabled={data.state_type === 1}
                                        onToggle={(checked) => onUpdateStateType([data.suid], checked ? 0 : 3)}
                                    />
                                    <ToggleButton
                                        title={{ checked: "위치 공개", default: "위치 비공개" }}
                                        checked={data.address_open === 1}
                                        onToggle={(checked) => onUpdateAddressOpen([data.suid], checked ? 1 : 0)}
                                    />
                                    <ToggleButton
                                        title={{ checked: "거래중", default: "거래완료" }}
                                        checked={data.state_type !== 1}
                                        disabled={data.state_type === 1}
                                        onToggle={(checked) => onUpdateStateType([data.suid], 1)}
                                    />
                                </div>
                            </td>
                        );
                    case "addition":
                        return (
                            <td key={`${data.suid}_${column.key}`}>
                                <div className={styles.additionContainer}>
                                    <AdditionButtonsMemo
                                        title={'메모 작성'}
                                        icon={!(data['sell_name'] || data['sell_phone'] || data['buy_name'] || data['buy_phone'] || data['memo']) ? icons.note.grey : icons.note.blue}
                                        deactive={!(data['sell_name'] || data['sell_phone'] || data['buy_name'] || data['buy_phone'] || data['memo'])}
                                        data={data}
                                        onClick={onUpdateMemo}
                                    />
                                    <AdditionButtonsReport
                                        title={'보고서 작성'}
                                        icon={!data.report_uuid ? icons.report.grey : icons.report.blue}
                                        deactive={!data.report_uuid}
                                        data={data}
                                        onClick={() => { legacy.openRequestReport({ address : data.address, uuid: data.suid }); }}
                                    />
                                </div>
                            </td>
                        );
                    case "manage":
                        const share_btn = (base, category, uuid) => {
                            var sendUrl = window.location.origin + "/m/" + uuid;
                            legacy.share_toast_target_id = legacy.jQuery(base).closest("td");
                            legacy.set_share(sendUrl, category, 'estate_agent', { position: { right: '20px' } });
                        };

                        return (
                            <td key={`${data.suid}_${column.key}`} className={styles.fixedCell}>
                                <ManageCell
                                    items={[
                                        { icon: icons.edit.grey, label: '수정', onClick: () => { legacy.em_modify_estate_data('sale', data.suid) } },
                                        { icon: icons.delete.grey, label: '삭제', onClick: () => { onDeleteSale([data.suid])  } },
                                        { icon: icons.share.grey, label: '공유', onClick: (e) => { e.stopPropagation(); share_btn(e.target, 'sale', data.suid) } },
                                    ]}
                                />
                            </td>
                        );
                    default:
                        return <td key={`${data.suid}_${column.key}`}>{data[column.key]}</td>;
                }
            })}
        </tr>
    );
};



const RenderItem = ({
    data,
    onCheckItem,
    checkedList,
    onUpdateAddressOpen,
    onUpdateStateType,
    onUpdateMemo,
    onDeleteSale,
    onDetailSaleView,
    isMobile
}) => {


    const [showMemo, setShowMemo] = useState(false);
    const memoRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (memoRef.current && !memoRef.current.contains(event.target)) {
                setShowMemo(false);
            }
        };

        if (showMemo) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }
    }, [showMemo]);

    const handleCheckItem = useCallback((e) => {
        e.stopPropagation();
        onCheckItem(data.suid);
    }, [data.suid, onCheckItem]);

    return (
        <div className={`${styles.listItem} ${data.state_type === 1 ? styles.doneItem : ''} ${checkedList.includes(data.suid) ? styles.checkedItem : ''}`} >
            <div className={`${styles.cursorPointer}`} onClick={() => onDetailSaleView(data.suid)}>
                <RowFlex gap="12px">
                    <div key={`${data.suid}_'checked`} style={{position : 'relative'}} onClick={handleCheckItem}>
                        <CheckboxCell
                                value={data.suid}
                                checked={checkedList.includes(data.suid)}
                        />
                        <div>
                            <PhotoCell photoUrl={data.photo}></PhotoCell>
                        </div>
                    </div>


                    <VerticalFlex className={`${styles.contentContainer}`} gap="4px">
                        <HorizontalFlex
                            leftChildren={<TypeCell data={data} />}
                            rightChildren={<DateDiifCell creationDate={data.creation_date} />}
                        >
                        </HorizontalFlex>

                        <div className={styles.buildingName}>{data.address} {data.buildingName && `(${data.buildingName})`}</div>
                        <PriceCell dealType={data.deal_type} price={data.price} deposit={data.deposit} monthly={data.monthly} />
                    </VerticalFlex>
                </RowFlex>

                <RowFlex style={{ marginTop: '12px' }}>
                    <div className={styles.controlContainer}>
                        <ToggleButton
                            title={{ checked: "매물 노출", default: "매물 비노출" }}
                            checked={data.state_type === 0}
                            disabled={data.state_type === 1}
                            onToggle={(checked) => onUpdateStateType([data.suid], checked ? 0 : 3)}
                        />
                        <ToggleButton
                            title={{ checked: "위치 공개", default: "위치 비공개" }}
                            checked={data.address_open === 1}
                            onToggle={(checked) => onUpdateAddressOpen([data.suid], checked ? 1 : 0)}
                        />
                        <ManageCell
                            style={{ marginLeft: 'auto' }}
                            items={
                                [
                                    { icon: icons.edit.coolGrey, label: '수정', onClick: () => { legacy.em_modify_estate_data('sale', data.suid) } },
                                    { icon: icons.delete.coolGrey, label: '삭제', onClick: () => { onDeleteSale([data.suid]) } },
                                    { icon: icons.share.coolGrey, label: '공유', onClick: (e) => { legacy.em_click_share_btn(e, e.target, 'sale', data.suid) } },
                                    { icon: icons.note.coolGrey, label: '메모 작성', onClick: (e) => { setShowMemo(prev => !prev) } },
                                    { icon: icons.report.coolGrey, label: '보고서 작성', onClick: (e) => { legacy.openRequestReport({ address : data.address, uuid: data.suid }); } },
                                    ...(data.state_type !== 1 ? [{ icon: icons.saleState.done.coolGrey, label: '거래완료 상태변경', onClick: (e) => { onUpdateStateType([data.suid], 1); } }] : [])
                                ]
                            }
                        />
                    </div>
                </RowFlex>
            </div>
            {
    showMemo && (
        <>
            {isMobile && (
                <div 
                    className={styles.backgroundOverlay} 
                    onClick={() => setShowMemo(false)}
                />
            )}
            <div className={`${styles.popupContainer} ${isMobile ? styles.isMobile : '' }`} ref={memoRef}>
                <HorizontalFlex
                    className={styles.header}
                    leftChildren={(
                        <div style={{ marginTop: '8px' }}>메모 작성</div>
                    )}
                    rightChildren={(
                        <div className={styles.cursorPointer} style={{ padding: '12px' }} onClick={() => setShowMemo(false)}>
                            <img src={icons.close.black} style={{ width: '24px', height: '24px' }} />
                        </div>
                    )}
                />
                <MemoEditor data={data} onClick={onUpdateMemo} />
            </div>
        </>
    )
}


        </div>
    )
}