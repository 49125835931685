import React from "react";

import styles from './buttons.module.scss'

const CONFIRM_BUTTON_THEME = {
    DEFAULT : styles.blue,
    BLUE : styles.blue,
    GREY : styles.grey,
    BLACK : styles.black
}

const DefaultConfirmButton = ({
    className = "",
    disabled = false,
    onClick = () => { },
    theme=CONFIRM_BUTTON_THEME.DEFAULT,
    label = "",
    children=null,
    style={}
}) => {

    return (
        <button className={`${styles.confirmButton}  ${styles.button}  ${theme} ${disabled ? styles.disabled : ""} ${className}`}
            style={ {...style} }
            onClick={!disabled ? onClick : undefined}
            disabled={disabled}
        >
            {children}
            {label}
        </button>
    )
}

export { CONFIRM_BUTTON_THEME, DefaultConfirmButton }



