
// 가격 관련 함수
export function priceFormat(value) {
    if (typeof value !== "number" || isNaN(value) || value <= 0 || value >= 10_000_000_000) {
        return "-";
    }

    const unitWords = ["만원", "억", "조"];
    const splitUnit = 10_000; // 기준 단위: 만원
    let result = [];
    let remaining = Math.round(value); // 소수점 반올림 처리

    for (let i = unitWords.length - 1; i > 0; i--) {
        const unitValue = Math.pow(splitUnit, i);
        if (remaining >= unitValue) {
            const unitAmount = Math.floor(remaining / unitValue);
            remaining %= unitValue;
            result.push(`${unitAmount.toLocaleString()}${unitWords[i]}`);
        }
    }

    if (remaining > 0 || result.length === 0) {
        result.push(`${remaining.toLocaleString()}${unitWords[0]}`);
    }

    return result.join(" ");
}
