import React from "react";
import styles from "./layout.module.scss"
import { VIEW_MODES } from "./layoutConst";
const AdaptiveBodyLayout = ({
    view = VIEW_MODES.SIDE,
    className = "",
    sticky=false,
    style={},
    children
}) => {
    const getViewClass = (_view) => {
        if (_view === VIEW_MODES.FULL) {
            return styles.fullLayout;
        } else if (_view === VIEW_MODES.SIDE) {
            return styles.sideLayout;
        } else if (_view === VIEW_MODES.FIXED) {
            return styles.fixedLayout;
        }

    }
    return (
        <div className={`${styles.layoutActive} ${styles.backgroundWhite} ${styles.body} ${getViewClass(view)} ${className} ${sticky ? styles.sticky : ''}`} style={ {...style }}>
            {children}
        </div>
    );
};

export { AdaptiveBodyLayout };
