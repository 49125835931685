import React from "react";
import styles from './text.module.css'

const PageTitleText = ({
    className = "",
    text = "",
}) => {
    return (
        <div className={`${styles.titleTextContainer} ${className}`}>{text}</div>
    )
}

export { PageTitleText };
