import React, { useState, useEffect, useRef } from "react";
import icons from "../../assets/images/icons";
import styles from "./Select.module.scss";
import { HorizontalFlex } from "../flex/HorizontalFlex";
import { BottomSheet } from "../material/Popup";
import { VerticalFlex } from "../flex/VerticalFlex";

const DefaultSelectButton = ({
    className = "",
    isSelected = "",
    placeholder = "선택",
    onClick = () => { },
}) => {
    return (
        <div className={`${styles.defaultSelectButton} ${isSelected ? styles.selected : ''} ${className}`} onClick={onClick}>
            <span>{placeholder}</span>
            <img src={isSelected ? icons.arrow.down.blue : icons.arrow.down.black} ></img>
        </div>
    )
}

const DropdownSelect = ({
    className = "",
    placeholder = "선택",
    options = [],
    selected = [],
    multiple = false,
    onChange = () => { },
    labelSeperator = ','
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState(selected);
    const popoverRef = useRef(null);
    const [label, setLabel] = useState(placeholder);

    const togglePopover = () => setIsOpen(!isOpen);

    const handleSelect = (value) => {
        setSelectedValues((prevValues) => {
            let updatedValues = [...prevValues];
            if (multiple) {
                if (updatedValues.includes(value)) {
                    updatedValues = updatedValues.filter((v) => v !== value);
                } else {
                    updatedValues.push(value);
                }
            } else {
                updatedValues = [value];
                setIsOpen(false);
            }
            return updatedValues;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }
    }, [isOpen]);

    useEffect(() => {
        onChange(selectedValues);
        if (selectedValues.length > 0) {
            const selectedLabels = options
                .filter((option) => selectedValues.includes(option.value))
                .map((option) => option.label)
                .join(`${labelSeperator} `);
            setLabel(selectedLabels);
        } else {
            setLabel(placeholder);
        }
    }, [selectedValues, options, placeholder]);

    useEffect(()=>{
        setSelectedValues(selected);
    }, [selected])

    return (
        <div ref={popoverRef} className={`${styles.withPopover} ${className}`}>
            <DefaultSelectButton
                placeholder={label}
                onClick={togglePopover}
            />
            {isOpen && (
                <div className={`${styles.dropdownContainer} ${styles.popover}`}>
                    {options.map((option, index) => (
                        <button
                            key={index}
                            className={`${styles.item} ${selectedValues.includes(option.value) ? styles.selected : ""}`}
                            onClick={() => handleSelect(option.value)}
                        >
                            {option.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

const PopoverChipSelect = ({
    className = "",
    placeholder = "선택",
    label = "",
    options = [],
    selected = [],
    multiple = false,
    onChange = () => { },
    showAllOption = false,
    allOptionLabel = "전체",
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState(selected);
    const popoverRef = useRef(null);
    const [selectAll, setSelectAll] = useState(false);

    const togglePopover = () => setIsOpen(!isOpen);

    const handleSelectAll = () => {
        setSelectedValues([]);
    }

    const handleSelect = (value) => {
        setSelectedValues((prevValues) => {
            let updatedValues = [...prevValues];
            if (multiple) {
                if (updatedValues.includes(value)) {
                    updatedValues = updatedValues.filter((v) => v !== value);
                } else {
                    updatedValues.push(value);
                }
                if (updatedValues.length == options.length) {
                    updatedValues = [];
                }
            } else {
                updatedValues = [value];
                setIsOpen(false);
            }
            return updatedValues;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
            document.addEventListener("touchstart", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("touchstart", handleClickOutside);
        }
    }, [isOpen]);


    useEffect(()=>{
        setSelectedValues(selected);
    }, [selected])

    useEffect(() => {
        setSelectAll(selectedValues.length === options.length || selectedValues.length == 0);
        onChange(selectedValues);
    }, [selectedValues]);

    return (
        <div ref={popoverRef} className={`${styles.withPopover} ${className}`}>
            <DefaultSelectButton
                isSelected={selectedValues.length > 0}
                placeholder={placeholder}
                onClick={togglePopover}
            />
            {isOpen && (
                <div className={`${styles.popoverChipContainer} ${styles.popover}`}>
                    <HorizontalFlex
                        className={`${styles.header}`}
                        leftChildren={
                            <>
                                <div className={`${styles.label}`}>{label}</div>
                                {multiple && <div className={`${styles.isMultipleLabel}`}>중복선택가능</div>}
                            </>
                        }
                        rightChildren={
                            <div className={`${styles.close}`} onClick={() => { setIsOpen(false) }}>
                                <img src={icons.close.black} width={17} height={17}></img>
                            </div>
                        }
                    />
                    <div className={`${styles.body}`}>
                        {multiple && showAllOption && (
                            <button
                                className={`${styles.chip} ${selectAll ? styles.selected : ""}`}
                                onClick={() => { if (!selectAll) { handleSelectAll(); } }}
                            >
                                {allOptionLabel}
                            </button>
                        )}

                        {options.map((option, index) => (
                            <button
                                key={index}
                                className={`${styles.chip} ${selectedValues.includes(option.value) && !selectAll ? styles.selected : ""}`}
                                onClick={() => handleSelect(option.value)}
                            >
                                {option.label}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

const BottomSheetSelect = ({
    className = "",
    placeholder = "선택",
    label = "",
    options = [],
    selected = [],
    multiple = false,
    onChange = () => { },
    showAllOption = false,
    allOptionLabel = "전체",
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValues, setSelectedValues] = useState(selected);
    const popoverRef = useRef(null);
    const [selectAll, setSelectAll] = useState(false);

    const togglePopover = () => setIsOpen((prev) => !prev);

    const handleSelectAll = () => {
        setSelectedValues([]);
    };

    const handleSelect = (value) => {
        setSelectedValues((prevValues) => {
            let updatedValues = [...prevValues];
            if (multiple) {
                if (updatedValues.includes(value)) {
                    updatedValues = updatedValues.filter((v) => v !== value);
                } else {
                    updatedValues.push(value);
                }
                if (updatedValues.length === options.length) {
                    updatedValues = [];
                }
            } else {
                updatedValues = [value];
                setIsOpen(false);
            }
            return updatedValues;
        });
    };

    useEffect(()=>{
        setSelectedValues(selected);
    }, [selected])
    
    useEffect(() => {
        onChange(selectedValues);
        setSelectAll(selectedValues.length === options.length || selectedValues.length === 0);
    }, [selectedValues]);

    return (
        <div ref={popoverRef} className={className}>
            <DefaultSelectButton
                isSelected={selectedValues.length > 0}
                placeholder={placeholder}
                onClick={togglePopover}
            />
            
            <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <VerticalFlex className={styles.bottomSheetContainer}>
                    <HorizontalFlex
                        className={styles.header}
                        leftChildren={
                            <>
                                <div className={styles.label}>{label}</div>
                                {multiple && <div className={styles.isMultipleLabel}>중복 선택 가능</div>}
                            </>
                        }
                        rightChildren={
                            <div className={styles.close} onClick={() => setIsOpen(false)}>
                                <img src={icons.close.black} width={24} height={24} />
                            </div>
                        }
                    />
                    <div className={styles.body} expand={true}>
                        <div >
                            {multiple && showAllOption && (
                                <button
                                    className={`${styles.chip} ${selectAll ? styles.selected : ""}`}
                                    onClick={() => { if (!selectAll) { handleSelectAll(); } }}
                                >
                                    <div>
                                    {allOptionLabel}
                                    </div>
                                    <div className={`${styles.iconContainer}`}>
                                        {selectAll ? <img className={`${styles.checkIcon}`}  src={icons.check.blue}></img> : null}
                                    </div>
                                    
                                </button>
                            )}

                            {options.map((option, index) => (
                                <button
                                    key={index}
                                    className={`${styles.chip} ${selectedValues.includes(option.value) && !selectAll ? styles.selected : ""}`}
                                    onClick={() => handleSelect(option.value)}
                                >
                                    <div>
                                    {option.label}
                                    </div>
                                    <div className={`${styles.iconContainer}`}>
                                        {selectedValues.includes(option.value) && !selectAll  ? <img className={`${styles.checkIcon}`}  src={icons.check.blue}></img> : null}
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>

                </VerticalFlex>
            </BottomSheet>
        </div>
    );
};

export { DropdownSelect, PopoverChipSelect,BottomSheetSelect }