import React from "react";
import styles from './toggleButtons.module.scss';
import { RowFlex } from "../flex/RowFlex";

const SwitchToggleButton = ({
    className = "",
    checked = false,
    onChange = () => {},
    onLabel = null,
    offLabel = null,
    style = {}
}) => {
    return (
        <div
            className={`${styles.switchToggleButtons} ${className}`}
            style={{ ...style }}
            onClick={() => onChange(!checked)} 
        >
            <button className={`${styles.button} ${styles.label} ${!checked ? styles.active : '' }`}>{offLabel}</button>
            <button className={`${styles.button} ${styles.label} ${checked ? styles.active : '' }`}>{onLabel}</button>
        </div>
    );
};

export { SwitchToggleButton };
