import React from "react";
import styles from "./buttons.module.scss";

const IconButton = ({
    className = "",
    disabled = false,
    highlighted = false,
    onClick = () => {},
    src="",
    width = '40px',
    height = '40px',
}) => {

    return (
        <button
            className={`
                ${styles.iconButton}
                ${styles.button} 
                ${disabled ? styles.disabled : ''} 
                ${highlighted ? styles.highlighted : ''} 
                ${className}
            `}
            onClick={!disabled ? onClick : undefined}
            disabled={disabled}
            style={{height: width, width : height}}
        >
            {src && <div style={{backgroundImage: `url(${src})`}}  className={styles.image} />}
        </button>
    );
};

const StatefulIconButton = ({
    className = "",
    disabled = false,
    highlighted = false,
    onClick = () => {},
    imageSrcSet = {
        enabledNormal: "",
        enabledHighlighted: "",
        disabledNormal: "",
        disabledHighlighted: "",
    },
    width = '40px',
    height = '40px',
}) => {

    const getImageSrc = (imageSrcSet, disabled, highlighted) => {
        if (disabled) {
            return highlighted ? imageSrcSet.disabledHighlighted : imageSrcSet.disabledNormal;
        }
        return highlighted ? imageSrcSet.enabledHighlighted : imageSrcSet.enabledNormal;
    };

    const currentImageSrc = getImageSrc(imageSrcSet, disabled, highlighted);

    return (
        <button
            className={`
                ${styles.statefulIconButton}
                ${styles.button} 
                ${disabled ? styles.disabled : ''} 
                ${highlighted ? styles.highlighted : ''} 
                ${className}
            `}
            onClick={!disabled ? onClick : undefined}
            disabled={disabled}
            style={{height: width, width : height}}
        >
            {currentImageSrc && <div style={{backgroundImage: `url(${currentImageSrc})`}}  className={styles.image} />}
        </button>
    );
};

export { IconButton, StatefulIconButton };
