import React, { useState, useEffect } from "react";
import styles from "./Popup.module.scss";



const BottomSheet = ({
    isOpen = false,
    onClose = () => { },
    children,
}) => {
    const [visible, setVisible] = useState(isOpen);
    const [isAnimating, setIsAnimating] = useState(false); 

    useEffect(() => {
        if (isOpen) {
            setVisible(true);
            setTimeout(() => setIsAnimating(true), 10);
        } else {
            setIsAnimating(false); 
            setTimeout(() => setVisible(false), 300); 
        }
    }, [isOpen]);

    if (!visible) return null;

    return (
        <>
            <div
                className={`${styles.bottomSheetOverlay} ${isAnimating ? styles.show : ""}`}
                onClick={onClose} 
            ></div>

            <div className={`${styles.bottomSheet} ${isAnimating ? styles.open : ""}`}>
                <div className={styles.bottomSheetContent}>{children}</div>
            </div>
        </>
    );
};


const ModelPopup = ({
    isOpen = false,
    position  = 'fixed',
    onClose = () => { },
    children,
}) => {
    const [visible, setVisible] = useState(isOpen);
    const [isAnimating, setIsAnimating] = useState(false); 

    const handleClose = () => {
        onClose?.();
    }

    useEffect(() => {
        if (isOpen) {
            setVisible(true);
            setTimeout(() => setIsAnimating(true), 10);
        } else {
            setIsAnimating(false); 
            setTimeout(() => setVisible(false), 300); 
        }
    }, [isOpen]);

    if (!visible) return null;

    return (
        <>
            <div
                className={`${styles.modalPopupOverlay} ${isAnimating ? styles.show : ""}`}
                style={{ position }}
                onClick={handleClose} 
            ></div>

            <div className={`${styles.modalPopup} ${isAnimating ? styles.open : ""}`} style={{ position }}>
                <div className={styles.modalContent}>{children}</div>
            </div>
        </>
    );

}
export { BottomSheet , ModelPopup};
