import React from "react";
import ReactDOM from "react-dom/client";

import EstateManagePage from "./pages/estateManage/EstateManagePage";
// 개발용 WebSocket을 차단
if (process.env.NODE_ENV === "development") {
    console.log("⚠️ WebSocket 차단됨 (HMR, Fast Refresh 비활성화)");
    window.__REACT_DEVTOOLS_GLOBAL_HOOK__ = { isDisabled: true }; // DevTools WebSocket도 차단
}
// 여러 개의 React 컴포넌트를 저장
const components = {
    EstateManage : EstateManagePage
};

const rootInstances = {}; 
const updateReactComponentState = (elementId, newState) => {
    if (rootInstances[elementId] && rootInstances[elementId].setState) {
        rootInstances[elementId].setState(prevState => ({
            ...prevState,
            ...newState
        }));
    } else {
        console.error(`❌ Component with ID ${elementId} not found or setState is undefined!`);
    }
};

window.renderReactComponentByName = (componentName, elementId, props = {}) => {
    const Component = components[componentName];
    if (!Component) {
        console.error(`❌ Component ${componentName} not found!`);
        return;
    }

    let element = document.getElementById(elementId);
    if (!element) {
        element = document.createElement('div');
        element.id = elementId;
        element.className = 'react-root';
        document.body.appendChild(element);
    }

    if (!rootInstances[elementId]) {
        const root = ReactDOM.createRoot(element);
        rootInstances[elementId] = {
            root,
            setState: null, // 상태 업데이트 함수 저장
        };
    }

    const ComponentWrapper = () => {
        const [state, setState] = React.useState({history : "show"});

        // 상태 변경 함수를 저장
        rootInstances[elementId].setState = setState;

        return <Component {...props} state={state} />;
    };

    rootInstances[elementId].root.render(<ComponentWrapper />);
};


window.showReactComponentByName = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.style.display = ''; // Make it visible
        updateReactComponentState(elementId, {history : "show"});
    }
};

window.hideReactComponentByName = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
        updateReactComponentState(elementId,  {history : "hide"});
        element.style.display = 'none'; // Hide it
    }
};

window.unmountReactComponentByName = (elementId) => {
    if (rootInstances[elementId]) {
        updateReactComponentState(elementId, {history : "destroy"});
        rootInstances[elementId].root.unmount();
        const element = document.getElementById(elementId);
        element.remove();
        delete rootInstances[elementId]; // 메모리에서 제거
    }
};
