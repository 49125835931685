import React,  { useEffect, useRef, useState }  from "react";
import styles from "./layout.module.scss"
import { VIEW_MODES } from "./layoutConst";
import { VerticalFlex } from "../flex/VerticalFlex";
const RootFrameLayout = ({
    className = "",
    children
}) => {
    return (
        <VerticalFlex className={`${styles.rootFrame} ${className}` }>
            {children}
        </VerticalFlex>
    );
};

const ScrollableFrameLayout = ({ children }) => {
    const containerRef = useRef(null);
    const [showXScroll, setShowXScroll] = useState(false);
    const [showYScroll, setShowYScroll] = useState(false);
    const [xScrollWidth, setXScrollWidth] = useState(0);
    const [xScrollLeft, setXScrollLeft] = useState(0);
    const [yScrollHeight, setYScrollHeight] = useState(0);
    const [yScrollTop, setYScrollTop] = useState(0);

    useEffect(() => {
        const updateScrollMetrics = () => {
            if (!containerRef.current) return;

            const { scrollWidth, clientWidth, scrollHeight, clientHeight, scrollLeft, scrollTop } = containerRef.current;

            const hasXScroll = scrollWidth > clientWidth;
            const hasYScroll = scrollHeight > clientHeight;

            setShowXScroll(hasXScroll);
            setShowYScroll(hasYScroll);

            if (hasXScroll) {
                const barWidth = (clientWidth / scrollWidth) * clientWidth;
                setXScrollWidth(barWidth);
                setXScrollLeft((scrollLeft / (scrollWidth - clientWidth)) * (clientWidth - barWidth));
            }

            if (hasYScroll) {
                const barHeight = (clientHeight / scrollHeight) * clientHeight;
                setYScrollHeight(barHeight);
                setYScrollTop((scrollTop / (scrollHeight - clientHeight)) * (clientHeight - barHeight));
            }
        };

        updateScrollMetrics();
        window.addEventListener("resize", updateScrollMetrics);
        containerRef.current?.addEventListener("scroll", updateScrollMetrics);

        return () => {
            window.removeEventListener("resize", updateScrollMetrics);
            containerRef.current?.removeEventListener("scroll", updateScrollMetrics);
        };
    }, []);

    const handleXScrollDrag = (e) => {
        if (!containerRef.current) return;
        const startX = e.clientX;
        const startScrollLeft = containerRef.current.scrollLeft;
        const maxScrollLeft = containerRef.current.scrollWidth - containerRef.current.clientWidth;

        const onMouseMove = (moveEvent) => {
            const deltaX = moveEvent.clientX - startX;
            containerRef.current.scrollLeft = Math.min(Math.max(startScrollLeft + (deltaX / (containerRef.current.clientWidth - xScrollWidth)) * maxScrollLeft, 0), maxScrollLeft);
        };

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    const handleYScrollDrag = (e) => {
        if (!containerRef.current) return;
        const startY = e.clientY;
        const startScrollTop = containerRef.current.scrollTop;
        const maxScrollTop = containerRef.current.scrollHeight - containerRef.current.clientHeight;

        const onMouseMove = (moveEvent) => {
            const deltaY = moveEvent.clientY - startY;
            containerRef.current.scrollTop = Math.min(Math.max(startScrollTop + (deltaY / (containerRef.current.clientHeight - yScrollHeight)) * maxScrollTop, 0), maxScrollTop);
        };

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseup", onMouseUp);
        };

        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp);
    };

    return (
        <div className={styles.webScrollableFrame}>
            <div ref={containerRef} className={styles.scrollContainer}>
                <div className={styles.scrollContent}>{children}</div>
            </div>
            {showXScroll && (
                <div
                    className={styles.scrollbarX}
                    style={{ width: `${xScrollWidth}px`, transform: `translateX(${xScrollLeft}px)` }}
                    onMouseDown={handleXScrollDrag}
                />
            )}
            {showYScroll && (
                <div
                    className={styles.scrollbarY}
                    style={{ height: `${yScrollHeight}px`, transform: `translateY(${yScrollTop}px)` }}
                    onMouseDown={handleYScrollDrag}
                />
            )}
        </div>
    );
};

export { RootFrameLayout, ScrollableFrameLayout };
