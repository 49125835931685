import React from "react";
import styles from "./flex.module.css";

const RowFlex = ({ children, className = "", gap = '0px'  , style={}}) => {
    return (
        <div className={`${styles.flexContainer} ${className}`} style={{gap : gap, ...style}}>
           {children}
        </div>
    );
};

export { RowFlex };
