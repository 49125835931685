import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "./ToastPopup.module.scss"; // SCSS 모듈 import
import icons from "../../assets/images/icons";


const Icon = {
  error : icons.alert.error,
  info : icons.alert.info,
}
const ToastContainer = () => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const handleToastEvent = (event) => {
      const { message, type, autoClose, customClass, position } = event.detail;
      addToast(message, type, autoClose, customClass, position);
    };

    window.addEventListener("show-toast", handleToastEvent);
    return () => window.removeEventListener("show-toast", handleToastEvent);
  }, []);

  const addToast = (message, type, autoClose, customClass, position) => {
    const id = Date.now();

    setToasts((prev) => {
      return position.includes("top") ? [{ id, message, type, customClass, position }, ...prev] : [...prev, { id, message, type, customClass, position }];
    });

    if (autoClose) {
      setTimeout(() => removeToast(id), autoClose);
    }
  };

  const removeToast = (id) => {
    setToasts((prev) => prev.filter((toast) => toast.id !== id));
  };

  const groupedToasts = toasts.reduce((acc, toast) => {
    if (!acc[toast.position]) acc[toast.position] = [];
    acc[toast.position].push(toast);
    return acc;
  }, {});

  return createPortal(
    <div className={styles["toast-wrapper"]}>
      {Object.entries(groupedToasts).map(([position, toastList]) => (
        <div key={position} className={`${styles["toast-container"]} ${styles[position]}`}>
          {toastList.map(({ id, message, type, customClass }, index) => (
            <div 
              key={id} 
              className={`${styles.toast} ${styles[`toast-${type}`]} ${customClass} ${
                position.includes("top") ? styles["top-animation"] : styles["bottom-animation"]
              }`}
              style={{
                transform: `translateY(0px)`,
                transition: "transform 0.3s ease-out",
              }}
            >
              {Icon[type] &&  <img src={Icon[type]} width={20} height={20}></img>}
              <div className={styles["toast-message"]}>{message}</div>
            </div>
          ))}
        </div>
      ))}
    </div>,
    document.body
  );
};

export const TOAST_POPUP_TYPE = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
};

export const TOAST_POSITION = {
  TOP_LEFT: "top-left",
  TOP_CENTER: "top-center",
  TOP_RIGHT: "top-right",
  BOTTOM_LEFT: "bottom-left",
  BOTTOM_CENTER: "bottom-center",
  BOTTOM_RIGHT: "bottom-right",
};

// 🔥 개선된 `toast` API (메서드 방식으로 호출 가능)
export const toast = {
  info: (message, options = {}) => showToast(message, TOAST_POPUP_TYPE.INFO, options),
  success: (message, options = {}) => showToast(message, TOAST_POPUP_TYPE.SUCCESS, options),
  error: (message, options = {}) => showToast(message, TOAST_POPUP_TYPE.ERROR, options),
  warning: (message, options = {}) => showToast(message, TOAST_POPUP_TYPE.WARNING, options),
};

// 내부 함수: `showToast`를 통해 이벤트 발생
const showToast = (message, type, { autoClose = 3000, customClass = "", position = TOAST_POSITION.TOP_RIGHT }) => {
  window.dispatchEvent(
    new CustomEvent("show-toast", { detail: { message, type, autoClose, customClass, position } })
  );
};

export default ToastContainer;
