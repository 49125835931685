import React, { useEffect, useState } from "react";
import styles from "./EstateManage.module.scss";
import { AdaptiveHeaderLayout } from "../../components/layouts/Header";
import { VIEW_MODES } from "../../components/layouts/layoutConst";
import { DefaultBackbutton } from "../../components/buttons/BackButton";
import { PageTitleText } from "../../components/text/CommonText";
import { HorizontalFlex } from "../../components/flex/HorizontalFlex";
import { RowFlex } from "../../components/flex/RowFlex";
import { AddressSearchBox } from "../../components/material/SearchBox";
import { IconButton, StatefulIconButton } from "../../components/buttons/IconButton";
import icons from "../../assets/images/icons";
import { PopoverChipSelect, DropdownSelect, BottomSheetSelect } from "../../components/select/Select";
import { ImageLabelTab, LabelTab, NormalTab } from "../../components/tab/Tab";
import { CONFIRM_BUTTON_THEME, DefaultConfirmButton } from "../../components/buttons/ConfirmButton";
import { SwitchToggleButton } from "../../components/buttons/ToggleButton";
import { VerticalFlex } from "../../components/flex/VerticalFlex";


const EstateManageHeader = ({
    payload = {},
    saleCount = {},
    onSetPayload = () => { },
    onResetPayload = () => {},
    checkedList = [],
    onSetCheckedList = () => { },
    data = {},
    filterModel = {},
    isSideView = false,
    isMobile = false,
    isWebScreen = false,
    isMapView = false,
    onChangeMapView = () => {},

    onChangeView = () => { },
    onBack = () => { },

    onUpdateAddressOpen = () => { },
    onUpdateStateType = () => { },
    onDeleteSale = () => {},

}) => {

    const [showCheckedListControl, setShowCheckedListControl] = useState(true);

    const handlePayload = (key, value) => {
        onSetPayload(prev => {            
            if (prev[key] !== value) {
                return {
                    ...prev,
                    [key]: value,
                    offset : 0
                };
            }
    
            return prev; 
        });
    };

    const renderHeaderFilterContainer = () => {
        const SelectComponent = isMobile ? BottomSheetSelect : PopoverChipSelect;
        const findOrderLabel = (options, valueToFind) => {
            const match = options.find(option => 
                JSON.stringify(option.value) === JSON.stringify(valueToFind)
            );
            return match ? match.label : "";
        };
        return (
            <RowFlex  className={`${isMobile ? styles.mobileHeader : ''}`} gap='8px' style={{ width: 'fit-content' }}>
                {/* <StatefulIconButton
            width="40px" height="40px" highlighted={filtered}
            onClick={() => { setFiltered(prev => !prev) }}
            imageSrcSet={{
                enabledNormal: icons.filter.black,
                enabledHighlighted: icons.filter.blue
            }
            }></StatefulIconButton> */}
                <IconButton
                    width="40px"
                    height="40px"
                    onClick={onResetPayload}
                    src={icons.refresh.grey}
                >

                </IconButton>
                {
                    isMobile && <SelectComponent
                        label={filterModel.order.label}
                        placeholder={findOrderLabel(filterModel.order.options, payload.order[0])}
                        options={filterModel.order.options}
                        selected={payload.order}
                        onChange={(value) => handlePayload('order', value)}
                    />
                }

                <SelectComponent
                    label={filterModel.estateType.label}
                    placeholder={filterModel.estateType.label}
                    options={filterModel.estateType.options}
                    selected={payload.rand_type}
                    multiple={true}
                    showAllOption={true}
                    onChange={(value) => handlePayload('rand_type', value)}
                    allOptionLabel={'전체'}
                />
                <SelectComponent
                    label={filterModel.dealType.label}
                    placeholder={filterModel.dealType.label}
                    options={filterModel.dealType.options}
                    selected={payload.deal_type}
                    multiple={true}
                    showAllOption={true}
                    onChange={(value) => handlePayload('deal_type', value)}
                    allOptionLabel={'전체'}
                />
                {/* <SelectComponent
            label={filterModel.location.label}
            placeholder={filterModel.location.label}
            options={filterModel.location.options}
            selected={payload.location}
            multiple={true}
            showAllOption={true}
            onChange={(value) => handlePayload('location', value)}
            allOptionLabel={'전체'}
        /> */}
                <SelectComponent
                    label={filterModel.addressOpen.label}
                    placeholder={filterModel.addressOpen.label}
                    options={filterModel.addressOpen.options}
                    selected={payload.address_open}
                    multiple={true}
                    showAllOption={true}
                    onChange={(value) => handlePayload('address_open', value)}
                    allOptionLabel={'전체'}
                />
            </RowFlex>
        )
    }
     
    const renderTopLeftHeader = () => (
        <RowFlex gap="24px">
            <RowFlex style={{ width: 'fit-content' }}>
                <DefaultBackbutton onClick={onBack} />
                <PageTitleText className={styles.titleMargin} text="매물관리" />
                <AddressSearchBox className={styles.searchBoxMargin} keyword={payload.keyword} onClick={(value) => handlePayload('keyword', value)} />
            </RowFlex>
            {renderHeaderFilterContainer()}
        </RowFlex>
    );

    const renderTopRightHeader = () => (
        <SwitchToggleButton
            checked={isSideView}
            onChange={onChangeView}
            offLabel={'리스트 보기'}
            onLabel={'지도 보기'}
        >
        </SwitchToggleButton>
    )

    const isVisibleCheckListControl = () => showCheckedListControl && checkedList.length > 0;

    const handleCloseControl = () => {
        onSetCheckedList([]);
        setShowCheckedListControl(false);
    }

    const renderBottomLeftHeader = () => {
        return (
            isVisibleCheckListControl() ? (
                <div className={`${styles.checkedListContorllerContainer}`}>
                    <RowFlex gap="8px">
                        <img className={`${styles.cursorPointer}`} src={icons.close.grey} style={{ height: '24px', width: '24px' }} onClick={() => { handleCloseControl() }} />
                        <div>
                            <span className={`${styles.accent}`}>{checkedList.length}개</span>
                            <span>&nbsp;매물 선택됨</span>
                        </div>
                    </RowFlex>
                    <div className={`${styles.dividier}`}></div>
                    <RowFlex gap="4px">
                        <button className={`${styles.controller}`} onClick={() => { onUpdateStateType(checkedList, 0) }}>
                            <img src={icons.saleState.visible.off} style={{ height: '24px', width: '24px' }} />
                            <div className={`${styles.tooltip}`}>매물 노출</div>
                        </button>
                        <button className={`${styles.controller}`} onClick={() => { onUpdateStateType(checkedList, 3) }}>
                            <img src={icons.saleState.invisible.off} style={{ height: '24px', width: '24px' }} />
                            <div className={`${styles.tooltip}`}>매물 비노출</div>
                        </button>
                        <button className={`${styles.controller}`} onClick={() => { onUpdateAddressOpen(checkedList, 1) }}>
                            <img src={icons.saleLocation.public} style={{ height: '24px', width: '24px' }} />
                            <div className={`${styles.tooltip}`}>위치 공개</div>
                        </button>
                        <button className={`${styles.controller}`} onClick={() => { onUpdateAddressOpen(checkedList, 0) }}>
                            <img src={icons.saleLocation.private} style={{ height: '24px', width: '24px' }} />
                            <div className={`${styles.tooltip}`}>위치 비공개</div>
                        </button>
                    </RowFlex>
                    <div className={`${styles.dividier}`}></div>
                    <button className={`${styles.controller}`} onClick={() => { onDeleteSale(checkedList) }}>
                        <img src={icons.delete.grey} style={{ height: '24px', width: '24px' }} />
                        <div className={`${styles.tooltip}`}>매물 삭제</div>
                    </button>
                </div>
            ) : (
                <ImageLabelTab
                    options={filterModel.state.options}
                    selected={payload.state_type}
                    onChange={(value) => handlePayload('state_type', value)}
                />
            )
        );
    };

    const renderBottomRightHeader = () => {
        return (
            <RowFlex gap={'12px'}>     
                <DropdownSelect
                    placeholder={filterModel.order.label}
                    options={filterModel.order.options}
                    selected={payload.order}
                    onChange={(value) => handlePayload('order', value)}
                ></DropdownSelect>
                <DropdownSelect
                    placeholder={filterModel.limit.label}
                    options={filterModel.limit.options}
                    selected={payload.limit}
                    onChange={(value) => handlePayload('limit', value)}
                ></DropdownSelect>
                <DefaultConfirmButton
                    className={`${styles.registerSale}`}
                    theme={CONFIRM_BUTTON_THEME.BLACK}
                    onClick={window.ifs_insert_popup}
                >
                    <RowFlex gap="8px">
                        <div>매물등록하기</div>
                        <img src={icons.add.white} width={20} height={20}></img>
                    </RowFlex>
                </DefaultConfirmButton>
            </RowFlex>

        )
    }

    useEffect(() => {
        setShowCheckedListControl(true);
    }, [checkedList])

    const renderHeader = () => (
        <AdaptiveHeaderLayout view={VIEW_MODES.FULL} className={styles.headerContainer}>
            <div className={styles.header}>
                <HorizontalFlex
                    className={`${styles.topHeader}`}
                    leftChildren={renderTopLeftHeader()}
                    rightChildren={renderTopRightHeader()}
                />

                <HorizontalFlex
                    className={`${styles.bottomHeader}`}
                    leftParentClassName={`${!isVisibleCheckListControl() ? styles.alignSelfEnd : ''}`}
                    leftChildren={renderBottomLeftHeader()}
                    rightChildren={renderBottomRightHeader()}
                />
            </div>

        </AdaptiveHeaderLayout>
    );

    const renderMobileHeader = () => (
        <AdaptiveHeaderLayout view={isWebScreen ?  VIEW_MODES.SIDE : VIEW_MODES.FULL} className={styles.mobileHeaderContainer}>
            <VerticalFlex gap="12px">
                <RowFlex className={`${styles.topHeader}`}>
                    <DefaultBackbutton onClick={onBack} />
                    <PageTitleText className={styles.title} text="매물관리" />
                    {
                        isWebScreen ?
                        <div style={{width : '24px'}}></div> :
                        (
                            <div onClick={() => (onChangeMapView(prev => !prev))}>
                            {
                                isMapView ? 
                                <img src={icons.list.grey} height={24} width={24}></img>
                                :
                                <img src={icons.map.grey} height={24} width={24}></img>
                            }
                        </div>
                        )
                    }

                </RowFlex>
                <div className={`${styles.sectionContainer} ${styles.searchBoxContainer}`}>
                    <AddressSearchBox  className={styles.searchBox} keyword={payload.keyword} onClick={(value) => handlePayload('keyword', value)} fullWidth={true}/>
                </div>
                <div className={`${styles.sectionContainer} ${styles.tabContainer}`}>
                <LabelTab
                    options={filterModel.state.options}
                    selected={payload.state_type}
                    onChange={(value) => handlePayload('state_type', value)}
                />
                </div>
                <div className={`${styles.sectionContainer} ${styles.overflow}`}>
                    {renderHeaderFilterContainer()}
                </div>

                
                <div className={`${styles.sectionContainer} ${styles.mobileShortcutContainer}`}>
                    <HorizontalFlex
                        leftChildren={<></>}
                        rightChildren={                    
                        <RowFlex gap="8px">
                            {
                                payload.keyword && 
                                (<div className={`${styles.keyword}`}>
                                    <span className={`${styles.word}`}>{payload.keyword}</span>
                                    <span>검색 결과</span>
                                </div>)
                            }

{
              data &&  <div className={`${styles.total}`}>총 {data.count}개</div>
}
    
                            {
                                saleCount.saleLimit  && <div className={`${styles.remain}`}>등록가능한 매물 <span className={`${styles.accent}`}>{saleCount.limit - saleCount.expose}개 남음</span></div>
                            }
                            </RowFlex>
                        }
                    >

                    </HorizontalFlex>

                </div>
            </VerticalFlex>

            <div className={`${styles.mobileRegisterSaleContainer}`}>
                <DefaultConfirmButton
                    className={`${styles.registerSale}`}
                    theme={CONFIRM_BUTTON_THEME.BLACK}
                    onClick={window.ifs_insert_popup}
                >
                    <RowFlex gap="8px">
                        <div>매물등록하기</div>
                        <img src={icons.add.white} width={20} height={20}></img>
                    </RowFlex>
                </DefaultConfirmButton>
            </div>
        
        </AdaptiveHeaderLayout>
    )

    return (
        <>
            {isMobile ? renderMobileHeader() : renderHeader()}
        </>
    );
};

export default EstateManageHeader;
