export const jsonToForm = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        if (Array.isArray(data[key])) {
            // 배열 데이터 처리 (예: 파일 업로드 또는 다중 선택 값)
            data[key].forEach((value, index) => {
                formData.append(`${key}[${index}]`, value);
            });
        } else {
            formData.append(key, data[key]);
        }
    });
    return formData;
}