const iconPaths = {
  add: {
    grey: "/icon-add@3x.png",
    white : "/icon-add-white@3x.png"
  },
  back: {
    black: "/back@3x.png",
  },
  close: {
    grey: "/ic-close-24@3x.png",
    black: "/ic-close-black@3x.png",
  },
  filter: {
    black: "/icon-filter-black@3x.png",
    blue: "/icon-filter-blue@3x.png",
  },
  arrow: {
    down: {
      black: "/arrow-down-black@3x.png",
      blue: "/arrow-down-blue@3x.png",
    },
    left: {
      black: "/arrows-chevron-left@3x.png",
    },
    right: {
      black: "/arrows-chevron-right@3x.png",
    },
  },
  squareArrow : {
    left: {
      black: "/arrow-left-black@3x.png",
    },
    right: {
      black: "/arrow-right-black@3x.png",
    },
  },
  saleLocation: {
    public: "/em-location-on@3x.png",
    private: "/em-location-off@3x.png",
  },
  saleState: {
    total: {
      off: "/em-search-total-off@3x.png",
      on: "/em-search-total-on@3x.png",
    },
    visible: {
      off: "/em-search-visible-off@3x.png",
      on: "/em-search-visible-on@3x.png",
    },
    invisible: {
      off: "/em-search-invisible-off@3x.png",
      on: "/em-search-invisible-on@3x.png",
    },
    done: {
      off: "/em-search-done-off@3x.png",
      on: "/em-search-done-on@3x.png",
      coolGrey : '/em-search-done-cool-grey@3x.png'
    },
  },
  delete: {
    grey: "/icon-delete-grey@3x.png",
    coolGrey : '/ic-delete-cool-grey@3x.png'
  },
  menu: {
    horizontal: {
      black : "/icon-more-horiz-black@3x.png",
      grey: "/icon-more-horiz-black@3x.png",
      blue : "icon-more-horiz-blue@3x.png",
    },
  },
  search: {
    grey: "/ic-search-grey@3x.png",
    black: "/ic-search-20@3x.png",
  },
  edit : {
    grey : "/ic-edit-grey@3x.png",
    coolGrey : "/ic-edit-cool-grey@3x.png"
  },
  share : {
    grey : '/icon-share-grey@3x.png',
    coolGrey : "/icon-share-cool-grey@3x.png"
  },
  report : {
    grey : '/icon-report-grey@3x.png',
    blue : '/icon-report-blue@3x.png',
    coolGrey : "/icon-report-cool-grey@3x.png"
  },
  note : {
    grey : '/icon-note-grey@3x.png',
    blue : '/icon-note-blue@3x.png',
    coolGrey : "/icon-note-cool-grey@3x.png"
  },
  saleNoData: "/icon-sale-no-data.png",
  refresh : {
    grey : '/icon-refresh-grey@3x.png'
  },
  map : {
    grey : '/icon-map-grey@3x.png'
  },
  list  : {
    grey : '/icon-list-grey@3x.png'
  },
  check : {
    blue : '/icon-check-blue@3x.png'
  },
  alert : {
    info : '/check-mark@3x.png',
    error : '/ic-exceptionerror-50@3x.png',
  },
  info : {
    red : '/icon-info-30@3x.png'
  },
  membership : {
    alert : '/img-membership-alert.png'
  },
  blankImage : {
    sale : '/sale-no-image-placeholder.png'
  }
};


const ICON_BASE_URL = process.env.REACT_APP_IMAGE_BASE_URL || "/images";

const mapObject = (base_url, obj, prefix = "") =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) =>
      typeof value === "string"
        ? [key, `${base_url}${prefix}${value}`]
        : [key, mapObject(base_url, value, prefix)] // 중첩 객체 처리
    )
  );

const icons = mapObject(ICON_BASE_URL, iconPaths, '/icon/');
export default icons;
