import React, { useEffect, useState } from "react";
import icons from "../../assets/images/icons";
import styles from "./SearchBox.module.scss";
import { HorizontalFlex } from "../flex/HorizontalFlex";
import { DefaultConfirmButton } from "../buttons/ConfirmButton";

const DefaultSearchBox = ({
    className="",
    keyword = "",
    placeholder = "",
    onClick = () => {},
    confirmButtonText = "",
    fullWidth = false 
}) => {
    const [searchKeyword, setSearchKeyword] = useState(keyword);
    useEffect(()=>{
        setSearchKeyword(keyword ?? '');
    }, [keyword]);


    const handleChange = (e) => {
        setSearchKeyword(e.target.value);
    };

    const handleClick = () => {
        onClick(searchKeyword);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleClick(); 
        }
    };

    return (
        <label className={`${ fullWidth ? styles.fullWidth : ''}`}>
        <HorizontalFlex
            className={`${styles.searchBox} ${className}`}
            gap="8px"
            leftChildren={
                <>
                    <img src={icons.search.grey} width={20} height={20} alt="검색" />
                    <input
                        className={`${styles.searchBoxInput}`}
                        placeholder={placeholder}
                        value={searchKeyword}
                        onChange={handleChange} 
                        onKeyDown={handleKeyDown}
                    />
                </>
            }
            rightChildren={
                <DefaultConfirmButton label={confirmButtonText} onClick={handleClick} />
            }
        />
        </label>
    );
};


const AddressSearchBox = ({ className = "", keyword = "", onClick = () => {},     fullWidth = false  }) => {
    return (
        <DefaultSearchBox
            className={className}
            keyword={keyword}
            placeholder="주소 또는 빌딩명을 검색해 보세요"
            confirmButtonText="검색"
            onClick={onClick}
            fullWidth = {fullWidth}
        />
    );
};


export { DefaultSearchBox, AddressSearchBox }





