import React from "react";
import styles from "./layout.module.scss";
import { VIEW_MODES } from "./layoutConst";

const AdaptiveHeaderLayout = ({
    view = VIEW_MODES.SIDE,
    className = "",
    children
}) => {
    return (
        <div className={`${styles.layoutActive} ${styles.backgroundWhite} ${view == VIEW_MODES.FULL ? styles.fullLayout : styles.sideLayout}  ${className}`}>
            {children}
        </div>
    );
};

export { AdaptiveHeaderLayout };
