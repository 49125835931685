const apiRequest = async (method, endpoint, data = null, params = null, isFormData = false) => {
    try {
        let url = `${endpoint}`;

        // GET 요청 시 쿼리 파라미터 추가
        if (params && method === "GET") {
            const queryString = new URLSearchParams(params).toString();
            url += `?${queryString}`;
        }

        const options = {
            method,
            headers: {},
        };

        // FormData가 아닌 경우 JSON 요청을 기본 설정
        if (!isFormData) {
            options.headers["Content-Type"] = "application/json";
        }

        if (data && method !== "GET") {
            options.body = isFormData ? data : JSON.stringify(data);
        }

        const response = await fetch(url, options);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        return response.json();
    } catch (error) {
        throw error;
    }
};

export const getData = async (endpoint, params = null) => {
    return apiRequest("GET", endpoint, null, params);
};

export const postData = async (endpoint, formData) => {
    return apiRequest("POST", endpoint, formData, null, true);
};

export const postJsonData = async (endpoint, data) => {
    return apiRequest("POST", endpoint, data);
};

export const putData = async (endpoint, data) => {
    return apiRequest("PUT", endpoint, data);
};

export const deleteData = async (endpoint) => {
    return apiRequest("DELETE", endpoint);
};

